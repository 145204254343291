import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { FormService } from '@services/form.service';
import { SessionStorageService } from '@services/session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ConsultationUpsellGuard implements CanActivate {
  constructor(
    private router: Router,
    private sessionStorage: SessionStorageService,
    private consultationRequestService: FormService
  ) {}

  canActivate(): boolean {
    if (!this.sessionStorage.upsell.length) {
      return true;
    }

    /**
     * TODO: Remove the backward compatibility code after the issue is resolved
     * @Issue: https://github.com/Medology/ng-checkout/issues/2527
     */
    const route = this.isDynamicQuestionnaire() ? this.getQuestionnaireFirstNavigationUrl() : '/consultation/request';
    const queryParams = {
      order_id: this.sessionStorage.transactionId,
      hash: this.sessionStorage.hash,
    };
    this.consultationRequestService.utiConsultationRequest.reset();
    this.sessionStorage.removeConsultationRequestCompletedData();
    this.router.navigate([route], { queryParams: queryParams });

    return false;
  }

  /**
   * Checks if the dynamic questionnaire is being used based for the current treatment type.
   *
   * @deprecated Remove this method after the issue is resolved
   * @Issue: https://github.com/Medology/ng-checkout/issues/2527
   *
   * @returns {boolean} true if the dynamic questionnaire is being used, false otherwise
   */
  private isDynamicQuestionnaire(): boolean {
    const treatmentType = this.sessionStorage.treatmentType;

    return !!treatmentType && !!this.sessionStorage.getQuestionnaireAnswers(treatmentType);
  }

  /**
   * Gets the first navigation URL from the questionnaire structure.
   *
   * @returns {string} the first navigation URL from the questionnaire structure
   */
  private getQuestionnaireFirstNavigationUrl(): string {
    return this.sessionStorage.getQuestionnaireStructure(this.sessionStorage.treatmentType).navigationUrls[0];
  }
}
