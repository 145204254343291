import { Inject, Injectable } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { cardExpirationValidator } from '@common/card-expiration-validator';
import { BillingCountry } from '@enums/billing-countries';
import { ConsultationTreatmentTypes } from '@enums/consultation-treatment-types';
import { PaymentTypes } from '@enums/payment-types';
import { Treatment } from '@enums/treatment';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { cloneDeep } from 'lodash';
import { Subject } from 'rxjs';

import { ConsultationRequestService } from './consultation-request.service';

@Injectable({
  providedIn: 'root',
})

/**
 * Define all the forms and allow their data to be shared between components.
 */
export class FormService {
  /**
   * Initialize the service.
   */
  constructor(@Inject(APP_CONFIG) private config: AppConfig, private fb: FormBuilder) {}

  /**
   * Triggers when the payment method changes.
   */
  public $paymentMethodObservable = new Subject<string>();

  /**
   * The form group definition for the guardian form.
   */
  private patientGuardianParentForm: FormGroup = this.fb.group({
    firstName: new FormControl(),
    lastName: new FormControl(),
    relationToChild: new FormControl(),
    attestation: new FormControl(),
  });

  /**
   * The patient information form definition.
   */
  private patientInfo: FormGroup = this.fb.group({
    firstName: new FormControl(null, [Validators.required, Validators.minLength(this.config.minimumNameLength)]),
    lastName: new FormControl(null, [Validators.required, Validators.minLength(this.config.minimumNameLength)]),
    sex: new FormControl(null, [Validators.required]),
    birthday: this.getDateForm(),
    contactVia: new FormControl('', [Validators.required]),
    phone: new FormControl(null),
    voicemail_allowed: new FormControl(false),
    email: new FormControl(null, [Validators.email]),
    partnerEmail: new FormControl(null, [Validators.email]),
    hasPartner: new FormControl(false),
    parentGuardian: this.patientGuardianParentForm,
  });

  /**
   * The order medical history form definition.
   */
  private orderMedicalHistory: FormGroup = this.fb.group({
    onMedication: new FormControl(null),
    medicationDetails: new FormControl(null),
    hasAllergies: new FormControl(null),
    allergiesDetails: new FormControl(null),
    hasOtherConditions: new FormControl(null),
    otherConditionsDetails: new FormControl(null),
  });

  /**
   * The order page service agreement form definition.
   */
  private serviceAgreement: FormGroup = this.fb.group({
    agreed: new FormControl(null),
  });

  /**
   * The UTI consultation request questions form definition.
   */
  private utiConsultationQuestionnaire: FormGroup = this.fb.group({
    preferredMedication: new FormControl(null, Validators.required),
    urinatingFrequently: new FormControl(null, Validators.required),
    ableToUrinateOverLast4Hours: new FormControl(null),
    urgesToGoToBathroom: new FormControl(null, Validators.required),
    burningWhileUrinating: new FormControl(null, Validators.required),
    possibleVaginalInfection: new FormControl(null, Validators.required),
    symptoms: new FormArray([]),
    nauseaOrVomiting: new FormControl(null, Validators.required),
    lowerBackPain: new FormControl(null, Validators.required),
    overTheCounterMedication: new FormControl(null),
    painFrequency: new FormControl(null),
    previousKidneyInfection: new FormControl(null),
    painSimilarToPreviousKidneyInfection: new FormControl(null),
    hadUrologicalProcedure: new FormControl(null, Validators.required),
    nonMenstrualBloodInUrine: new FormControl(null, Validators.required),
    elevatedOralTemperature: new FormControl(null, Validators.required),
    moreThan4AntiBioticsInThePastYear: new FormControl(null, Validators.required),
    diagnosedForUtiWithSimilarSymptoms: new FormControl(null, Validators.required),
    treatedForUtiInLast6Weeks: new FormControl(null),
    treatedForUtiInLast6WeeksDetails: new FormControl(null),
    utiResolvedQuickly: new FormControl(null),
    utisNotRespondingToTreatment: new FormControl(null),
    diabetes: new FormControl(null, Validators.required),
    insulin: new FormControl(null),
    hemoglobinA1CTestedInLastYear: new FormControl(null),
    levelGreaterThan7: new FormControl(null),
    presentlyHasKidneyStones: new FormControl(null, Validators.required),
    catheterPlacedRecently: new FormControl(null, Validators.required),
    possiblePregnancy: new FormControl(null, Validators.required),
    deliveredABabyInLastMonth: new FormControl(null, Validators.required),
    breastfeeding: new FormControl(null, Validators.required),
    exposedToSti: new FormControl(null, Validators.required),
    immuneSystemSuppressed: new FormControl(null, Validators.required),
    immuneSystemSuppressedDetails: new FormControl(null),
    diagnosedWithQtProlongation: new FormControl(null, Validators.required),
    onChemotherapy: new FormControl(null, Validators.required),
    dehydratedInLast3Weeks: new FormControl(null, Validators.required),
    traveledInternationallyInPast6Months: new FormControl(null, Validators.required),
    travelDetails: new FormControl(null),
    changesInMentalState: new FormControl(null),
    changesInAppetite: new FormControl(null),
    unintentionalWeightLossOrGain: new FormControl(null),
    primaryCareVisitInLast2Years: new FormControl(null),
    abnormalKidneyFunctionTests: new FormControl(null),
    attestToSchedulingFollowUp: new FormControl(null),
    noMenstrualPeriodInLastYear: new FormControl(null),
    onMedication: new FormControl(null, Validators.required),
    medicationDetails: new FormControl(null),
    hasAllergies: new FormControl(null, Validators.required),
    allergiesDetails: new FormControl(null),
    hasOtherConditions: new FormControl(null, Validators.required),
    otherConditionsDetails: new FormControl(null),
  });

  /**
   * The UTI consultation request upsells form definition.
   */
  private utiConsultationUpsells: FormGroup = this.fb.group({
    pyridium: new FormControl(null, Validators.required),
    followUpUrineTest: new FormControl(null, Validators.required),
    fluconazole: new FormControl(null),
    ondansetron: new FormControl(null),
    deliverMedication: new FormControl(null),
    probiotics: new FormControl(null),
    doctorsNote: new FormControl(null),
    levonorgestrel: new FormControl(null),
    mensIntimateWash: new FormControl(null),
  });

  /**
   * The payment form definition.
   */
  private paymentForm: FormGroup = this.fb.group({
    method: [PaymentTypes.CreditCard, Validators.required],
    giftCardCode: [''],
    creditCard: this.fb.group(
      {
        cardNumber: [''],
        cardMonth: [''],
        cardYear: [''],
        billingCountry: [BillingCountry.UnitedStates],
        billingZipCode: [''],
        cvv: [''],
      },
      { validators: cardExpirationValidator('cardMonth', 'cardYear') }
    ),
    nonce: [''],
    bitpay: this.fb.group({
      invoice: [''],
      amount: [''],
    }),
  });

  /**
   * The consultation request personal information form definition.
   */
  private consultationRequestPersonalInfo: FormGroup = this.fb.group({
    firstName: new FormControl(null, [Validators.required, Validators.minLength(this.config.minimumNameLength)]),
    lastName: new FormControl(null, [Validators.required, Validators.minLength(this.config.minimumNameLength)]),
    birthday: this.getDateForm(),
    height_feet: new FormControl(null),
    height_inches: new FormControl(null),
    weight: new FormControl(null),
  });

  /**
   * The consultation request consultation type form definition.
   */
  private consultationRequestConsultationType: FormGroup = this.fb.group({
    consultationType: new FormControl('Scheduled'),
  });

  /**
   * The consultation request contact information form definition.
   */
  private consultationRequestContactInfo: FormGroup = this.fb.group({
    email: new FormControl(null, [Validators.required, Validators.email]),
    phone: new FormControl(null, [Validators.required, Validators.pattern('[0-9]{10}')]),
  });

  /**
   * The consultation request payment information form definition.
   */
  private consultationRequestPaymentInfo: FormGroup = this.fb.group({
    discount: new FormControl(0),
    payment: this.getNewPaymentForm(),
  });

  /**
   * The consultation request medical history form definition.
   */
  private consultationRequestMedicalHistory: FormGroup = this.fb.group({
    allergies: new FormControl(null, [Validators.required, Validators.maxLength(1000)]),
    prescriptions: new FormControl(null, [Validators.required, Validators.maxLength(1000)]),
    race: new FormControl(null),
    ethnicity: new FormControl(null),
    sex_with: new FormControl(null, [Validators.required]),
    pregnant: new FormControl(null),
    menstrual_d: this.getDateForm(false),
    due_d: this.getDateForm(false),
    symptoms: this.getFormGroupFromSymptoms(),
    health_info: new FormControl(null),
  });

  /**
   * The consultation request prescription partner form definition.
   */
  private partnerPrescriptionForm: FormGroup = this.fb.group({
    firstName: new FormControl(null),
    lastName: new FormControl(null),
    gender: new FormControl(null),
    pregnant: new FormControl(null),
    birthday: this.getDateForm(false),
    allergies: new FormControl(null),
    prescriptions: new FormControl(null),
    symptoms: this.getFormGroupFromSymptoms(),
    height_feet: new FormControl(null),
    height_inches: new FormControl(null),
    weight: new FormControl(null),
  });

  /**
   * The consultation request prescription information form definition.
   */
  private consultationRequestPrescriptionInfo: FormGroup = this.fb.group({
    treatment: new FormControl(Treatment.Self, Validators.required),
    partner: this.partnerPrescriptionForm,
  });

  /**
   * The DoxyPep consultation request terms form definition.
   */
  private doxyPepQuestionnaireConsents: FormGroup = this.fb.group({
    tos1: new FormControl(false, Validators.requiredTrue),
    tos2: new FormControl(false, Validators.requiredTrue),
    tos3: new FormControl(null),
  });

  /**
   * The consultation request patient information form definition.
   */
  private consultationRequestPatientInfo: FormGroup = this.fb.group({
    first_name: new FormControl(null, [Validators.required, Validators.minLength(this.config.minimumNameLength)]),
    last_name: new FormControl(null, [Validators.required, Validators.minLength(this.config.minimumNameLength)]),
    birthday: this.getDateForm(),
    gender: new FormControl(null),
    height_feet: new FormControl(null),
    height_inches: new FormControl(null),
    weight: new FormControl(null),
  });

  /**
   * The default address form definition.
   */
  get address(): FormGroup {
    return new FormGroup({
      streetAddress: new FormControl(null, [Validators.required]),
      city: new FormControl(null, [Validators.required]),
      state: new FormControl(null, [Validators.required]),
      zipcode: new FormControl(null, [
        Validators.required,
        Validators.pattern('^[0-9]{5}$|^[A-Z][0-9][A-Z] ?[0-9][A-Z][0-9]$'),
      ]),
    });
  }

  /**
   * The upsell card form definition.
   */
  getUpsellCardForm(): FormGroup {
    return this.fb.group({
      upsell: new FormControl(null, [Validators.required]),
    });
  }

  /**
   * The checkout form definition.
   */
  checkout: FormGroup = this.fb.group({
    lab_id: ['', Validators.required],
    booking_id: [''],
    patient: this.patientInfo,
    payment: this.getNewPaymentForm(),
    secondaryPayment: this.getNewPaymentForm(),
    medicalHistory: this.orderMedicalHistory,
    serviceAgreement: this.serviceAgreement,
  });

  /**
   * The contact information form definition.
   */
  addressContactInformation: FormGroup = this.fb.group({
    phone: this.fb.group({
      number: new FormControl(null, [Validators.required, Validators.pattern('[0-9]{10}')]),
      voicemail_allowed: new FormControl(false, [Validators.required]),
      sms_allowed: new FormControl(false, [Validators.required]),
    }),
    address: this.address,
    tos: new FormControl(false, [Validators.requiredTrue]),
  });

  /**
   * The consultation request form definition.
   */
  consultationRequest: FormGroup = this.fb.group({
    type: this.consultationRequestConsultationType,
    treatmentType: new FormControl(ConsultationTreatmentTypes.Std),
    personal: this.consultationRequestPersonalInfo,
    contact: this.consultationRequestContactInfo,
    pharmacy: this.getPharmacyFormGroup(),
    prescription: this.consultationRequestPrescriptionInfo,
    payment: this.consultationRequestPaymentInfo,
    medicalHistory: this.consultationRequestMedicalHistory,
    terms: new FormControl(false, Validators.requiredTrue),
    additional_info: this.getAdditionalInfoForm(),
  });

  /**
   * The UTI consultation request form definition.
   */
  utiConsultationRequest: FormGroup = this.fb.group({
    patient: this.consultationRequestPatientInfo,
    pharmacy: this.getPharmacyFormGroup(),
    address: this.address,
    upsells: this.utiConsultationUpsells,
    additional_info: this.getAdditionalInfoForm(this.utiConsultationQuestionnaire),
  });

  /**
   * The consultation request form definition.
   */
  consultationRequestForm: FormGroup = this.fb.group({
    patient: this.consultationRequestPatientInfo,
    pharmacy: this.getPharmacyFormGroup(),
    address: this.address,
  });

  /**
   * The preferred medication form definition.
   */
  preferredMedicationForm: FormGroup = this.fb.group({
    preferredMedication: new FormControl(null),
  });

  /**
   * The DoxyPep consultation request form definition.
   *
   * Combine terms fields with existing controls from
   * the consultationRequestForm, excluding the address field.
   */
  stdPreventionConsultationRequest: FormGroup = this.fb.group({
    terms: this.doxyPepQuestionnaireConsents,
    // Exclude the 'address' control, include all other relevant controls from consultationRequestForm
    ...(({ address, ...remainingControls }) => remainingControls)(this.consultationRequestForm.controls),
  });

  /**
   * The UTI consultation request form definition.
   */
  consultationAttachment: FormGroup = this.fb.group({
    files: this.fb.array([]),
  });

  /**
   * Clears control errors and validators.
   *
   * @param {AbstractControl} control Form control
   */
  resetControl(control: AbstractControl): void {
    control.clearValidators();
    control.setErrors(null);
    control.updateValueAndValidity();
  }

  /**
   * Sets the validator and run the validation.
   *
   * @param {AbstractControl} control  Form control
   * @param {ValidatorFn[]} validators Form validators array
   */
  setValidator(control: AbstractControl, validators: ValidatorFn[]) {
    control.setValidators(validators);
    control.updateValueAndValidity();
  }

  /**
   * Dynamically returns the phone form group.
   */
  orderPhoneForm(): FormGroup {
    return this.fb.group({
      number: new FormControl(''),
      voicemail_allowed: new FormControl(false),
      sms_allowed: new FormControl(false),
    });
  }

  /**
   * Creates a form group from the possible symptoms.
   *
   * @returns a FormGroup with one control per symptom and all values set to false
   */
  getFormGroupFromSymptoms(): FormGroup {
    const symptomsFormGroup = new FormGroup({});

    ConsultationRequestService.symptoms.forEach((symptom) => {
      symptomsFormGroup.addControl(symptom.value, new FormControl(false));
    });

    return symptomsFormGroup;
  }

  /**
   * Validates that at least one control in the group has a true value.
   *
   * @returns a ValidatorFn that returns the error symptomsRequired if
   * the condition is not met, null otherwise
   */
  symptomSelectedValidator(): ValidatorFn {
    return (group: FormGroup): ValidationErrors | null => {
      const invalid = !Object.values(group.value).some((value) => value);
      return invalid ? { symptomsRequired: true } : null;
    };
  }

  /**
   * Creates a pharmacy information form.
   *
   * @returns a FormGroup to enter the pharmacy information
   */
  getPharmacyFormGroup(): FormGroup {
    const pharmacyFormGroup = new FormGroup({});

    pharmacyFormGroup.addControl('id', new FormControl(null, Validators.required));
    pharmacyFormGroup.addControl('name', new FormControl(null));
    pharmacyFormGroup.addControl('phone', new FormControl(null));
    pharmacyFormGroup.addControl('state', new FormControl(null));
    pharmacyFormGroup.addControl('address', new FormControl(null));
    pharmacyFormGroup.addControl('address2', new FormControl(null));
    pharmacyFormGroup.addControl('city', new FormControl(null));
    pharmacyFormGroup.addControl('title', new FormControl(null));
    pharmacyFormGroup.addControl('zip_code', new FormControl(null));

    return pharmacyFormGroup;
  }

  /**
   * Creates an additional info form group.
   *
   * @param {FormGroup} questionnaire the questionnaire form group
   */
  getAdditionalInfoForm(questionnaire: FormGroup = null): FormGroup {
    const additionalInfoFormGroup = new FormGroup({});

    if (questionnaire) {
      additionalInfoFormGroup.addControl('questionnaire', questionnaire);
    }

    return additionalInfoFormGroup;
  }

  /**
   * Creates a date form group with its month, day and year controls.
   *
   * @param addRequiredValidators whether or not to add the required validator to each control
   * @returns a FormGroup to enter the date information
   */
  getDateForm(addRequiredValidators: boolean = true): FormGroup {
    const validator = addRequiredValidators ? Validators.required : null;

    return new FormGroup({
      month: new FormControl(null, validator),
      day: new FormControl(null, validator),
      year: new FormControl(null, validator),
    });
  }

  /**
   * Gets a new instance of the payment form.
   *
   * @returns a FormGroup with the payment form definition
   */
  getNewPaymentForm(): FormGroup {
    return cloneDeep(this.paymentForm);
  }

  /**
   * Recursively disables all the controls within an AbstractControl (FormGroup, FormControl, or FormArray).
   *
   * @param control the control to disable
   */
  disableAllControls(control: AbstractControl): void {
    if (control instanceof FormControl) {
      control.disable();
    } else if (control instanceof FormGroup) {
      Object.values(control.controls).forEach((childControl) => this.disableAllControls(childControl));
    } else if (control instanceof FormArray) {
      control.controls.forEach((arrayControl) => this.disableAllControls(arrayControl));
    }
  }

  /**
   * Reset the form value and validators.
   *
   * @param {FormGroup} form the form to reset
   */
  resetFormValuesAndValidators(form: FormGroup): void {
    for (const field in form.controls) {
      form.controls[field].reset();
      form.controls[field].clearValidators();
      form.controls[field].clearAsyncValidators();
      form.controls[field].updateValueAndValidity();
    }
  }
}
