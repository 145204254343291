import { ConsultationTreatmentTypes } from '@enums/consultation-treatment-types';
import { Gender } from '@enums/gender';
import { UpsellSlugs } from '@enums/upsell-slugs';

/**
 * Environment variables for use on the Starfish site, both in production and local environment.
 */
export const starfishCommonEnvironment = {
  domain: 'Starfish.com',
  ngFindalab: {
    searchEndpoint: '/pharmacies/labs/nearPostalCode',
    filterByStates: null,
    excludeStates: ['PR'],
    showPhone: true,
    filterByTestAndSite: false,
    searchTitle: 'Enter Your Location',
    searchDescription:
      'Please choose a nearby pharmacy where our doctor can send your prescription once they have reviewed your medical information and your order has been placed.',
    pharmacySearchDescription:
      'Please choose a nearby pharmacy where our doctor can send your prescription once they have reviewed your medical information and your order has been placed.',
    locateMsg: 'Use my current location',
    labButtonText: 'Choose This Pharmacy',
    searchButtonText: 'Find',
    showSearchIcon: false,
    labIconClass: 'fa fa-arrow-right',
    searchMessageNoResults:
      'Oops! Sorry, we could not find any pharmacies near that location. Please try your search again with a different or less specific address.',
    inputGroup: {
      container: '',
      field: null,
      button: '',
    },
    resultsZoom: 12,
    pharmacyDescription: false,
    choosePharmacySearchTitle: 'Enter Your Location',
    showDayOfWeekFilter: false,
  },
  titles: {
    order: 'Checkout - Starfish',
    consultationRequest: 'Questionnaire - Starfish',
    headlineCheckoutScheduled: '',
    headlineCheckoutAsync: '',
    step1Title: 'Find a Pharmacy',
    selectedCenterLabel: 'Pharmacy',
    orderPageHeader: {
      title: "You're almost <strong>Done!</strong>",
      subtitle:
        'Thank you for selecting a pharmacy. We just need a few more details before determining your diagnosis.',
    },
    scheduledConsultationRequest: {
      title: 'Clinician Phone Consultation Needed',
      calendarDetails:
        'To ensure compliance with Telehealth regulations in your state, we must arrange a brief phone consultation with one of our clinicians before prescribing medication. This consultation is provided at no additional cost to you.',
      phoneNumberDetails: 'A Clinician will call you at',
      availabilityDetails:
        "Typically, our clinicians call earlier than the anticipated window, so there's no need to be concerned if your appointment appears to be scheduled further than expected.",
    },
  },
  buttons: {
    checkout: 'Place Order',
    selectedCenterChange: 'Change Pharmacy',
  },
  order: {
    allowedGenders: [Gender.Female],
    sexInvalidError: 'Starfish.com can only diagnose females',
    minAgeAllowed: 18,
  },
  styles: {
    chooseAPharmacy: {
      labButtonClass: 'is-secondary-orange has-text-white',
      searchButtonClass: 'is-green has-text-white',
      googleMapsLabMarkerFillColor: '#fa676b',
      googleMapsRecommendedMarkerFillColor: '#fdd8d9',
      googleMapsMarkerHoverFillColor: '#f84f55',
    },
    consultationRequest: {
      headlineClass: 'column is-flex is-justify-content-center pt-5 pb-0 has-text-weight-semibold',
    },
    healthLabsCoupon: {
      buttonColor: 'secondary-orange',
    },
    orderCompleteInstructions: {
      cardContentClass: '',
    },
    orderBetterLab: {
      orderPlaceNotificationClass: 'is-warning',
      currentTestCenterNotificationClass: 'is-info',
      keepCurrentLabButtonClass: 'is-uppercase',
      keepCurrentLabButtonColor: 'secondary-orange',
    },
    testCenter: {
      labButtonClass: '',
      searchButtonClass: 'is-secondary-orange has-text-white',
      googleMapsLabMarkerFillColor: '#fa676b',
      googleMapsRecommendedMarkerFillColor: '#fdd8d9',
      googleMapsMarkerHoverFillColor: '#f84f55',
    },
  },
  shopperApproved: {
    site: 40107,
    token: '330wDBfS',
  },
  orderCompleteSummary: {
    testOrderedWording: {
      single: 'Preferred Medication',
      plural: 'Preferred Medications',
    },
  },
  enablePayLater: false,
  enableBitPay: false,
  enableOrderUpgrade: false,
  enableOrderMedicalHistory: true,
  enableTosOnOrderPage: true,
  forceEmailResults: true,
  faviconFile: 'starfish-favicon.ico',
  inHomeCollectionAllowed: false,
  displayFindTestsModal: false,
  displaySisterSiteBanner: false,
  phone: '1-866-760-3593',
  email: 'support@starfish.com',
  validateSelectedLabNetwork: false,
  getNetworksFromTests: false,
  verifyTestsCanBeOrdered: false,
  isPartnerTestingAllowed: false,
  orderSummary: {
    cartAdditionalItems: [
      { description: 'Local Pharmacy Pickup', price: '$0.00' },
      {
        description: 'Doctor Review',
        tooltip: 'Our Physicians will review your medical information and contact you with your prescription.',
        price: 'Included',
      },
    ],
    allowEditingCart: false,
    editCartButtonText: 'Edit',
    displayCartSummaryTableHeaders: false,
    testsPrefix: 'Medication Online',
  },
  allowedRoutes: [
    '/null',
    '/order',
    '/order-complete.php',
    '/consultation/vaginal-cream',
    '/consultation/bacterial-vaginosis/request',
    '/consultation/uti/request',
    '/consultation/std/request',
    '/consultation/yeast-infection/request',
    '/consultation/yeast-infection-prevention',
    '/consultation/alleviate-nausea-and-vomiting',
    '/consultation/replenish-your-good-bacteria',
    '/consultation/be-prepared-for-the-unexpected',
    '/consultation/need-a-doctors-note',
    '/consultation/medication-pickup-or-delivery',
    '/consultation/upload-consult-attachments',
    '/consultation/schedule-consultation',
    '/consultation/clotrimazole-vaginal-cream',
    '/consultation/mens-intimate-wash',
    '/yeast-infection/consultation-request-completed',
    '/bacterial-vaginosis/consultation-request-completed',
    '/uti/consultation-request-completed',
    '/std/consultation-request-completed',
  ],
  showCouponCodeInSummary: false,
  consultationRequestUpsellPrices: {
    [UpsellSlugs.Pyridium]: 20,
    [UpsellSlugs.FollowUpUrineTest]: 29,
    [UpsellSlugs.Fluconazole]: 20,
    [UpsellSlugs.FluconazoleForBVTreatment]: 25,
    [UpsellSlugs.Metronidazole]: 25,
    [UpsellSlugs.Probiotics]: 10,
    [UpsellSlugs.DoctorsNote]: 10,
    [UpsellSlugs.Levonorgestrel]: 10,
    [UpsellSlugs.DeliverMedication]: 39,
    [UpsellSlugs.OndansetronPill]: 20,
    [UpsellSlugs.OndansetronDissolvableTablet]: 20,
    [UpsellSlugs.MetronidazoleVaginalGel]: 25,
    [UpsellSlugs.ClindamycinVaginalCream]: 25,
    [UpsellSlugs.ClotrimazoleVaginalCream]: 15,
    [UpsellSlugs.MensIntimateWash]: 29,
  },
  findalabMapId: '8f1673661122dda0',
  maxAgeAllowed: 150,
  sentryDsn: 'https://9ea1a33f276705615ff0d80b1152fa64@o510915.ingest.us.sentry.io/4507606318841856',
  treatmentFormSettings: {
    [ConsultationTreatmentTypes.Uti]: '1',
    [ConsultationTreatmentTypes.YeastInfection]: '2',
    [ConsultationTreatmentTypes.BacterialVaginosis]: '3',
    [ConsultationTreatmentTypes.StdPrevention]: null,
    [ConsultationTreatmentTypes.Std]: '5',
  },
};
