import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '@environments/environment';
import { healthlabsEnvironment } from '@environments/healthlabs/environment';
import { starfishEnvironment } from '@environments/starfish/environment';
import { stdcheckEnvironment } from '@environments/stdcheck/environment';
import { treatmyutiEnvironment } from '@environments/treatmyuti/environment';
import * as Sentry from '@sentry/angular';

import { AppModule } from './app/app.module';

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  environment: environment.production ? 'production' : 'local',
  dsn: getSentryDSN(),
  release: environment.sentry.release,
  sampleRate: parseFloat(environment.sentry.sampleRate),
  tracesSampleRate: parseFloat(environment.sentry.traceSampleRate),
  tracePropagationTargets: [
    'checkout.stdcheck.local',
    'checkout.stdcheck.com',
    'checkout.healthlabs.local',
    'checkout.healthlabs.com',
    'checkout.treatmyuti.local',
    'checkout.treatmyuti.com',
    'checkout.starfish.local',
    'checkout.starfish.com',
  ],
  integrations: [Sentry.browserTracingIntegration()],
  beforeSend(event) {
    event.tags = {
      ...event.tags,
      order_id: localStorage.getItem('transaction_id') ?? null,
    };

    return event;
  },
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

/**
 * Get the Sentry DSN based on the hostname.
 */
function getSentryDSN(): string {
  const domain = window.location.hostname.toLowerCase();
  const domainToEnvironment = {
    stdcheck: stdcheckEnvironment,
    treatmyuti: treatmyutiEnvironment,
    starfish: starfishEnvironment,
    healthlabs: healthlabsEnvironment,
  };

  for (const key of Object.keys(domainToEnvironment)) {
    if (domain.includes(key)) {
      return domainToEnvironment[key].sentryDsn;
    }
  }

  return '';
}
