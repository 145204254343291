import { Component, Inject } from '@angular/core';
import { UtiConsultationRequestUpsellComponent } from '@consultation-upsells/uti-consultation-request-upsell.component';
import { CustomerTestsTypes } from '@enums/customer-tests-types';
import { QuestionnaireAnswers } from '@enums/questionnaire-answers';
import { UpsellSlugs } from '@enums/upsell-slugs';
import { SelectableOption } from '@models/selectable-option';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { FormService } from '@services/form.service';
import { SessionStorageService } from '@services/session-storage.service';

@Component({
  selector: 'app-vaginal-cream',
  templateUrl: './vaginal-cream.component.html',
  styleUrls: ['./vaginal-cream.component.scss'],
})
export class VaginalCreamComponent extends UtiConsultationRequestUpsellComponent {
  protected slug: UpsellSlugs = UpsellSlugs.MetronidazoleVaginalGel;

  constructor(
    @Inject(APP_CONFIG) protected config: AppConfig,
    protected formService: FormService,
    protected sessionStorageService: SessionStorageService
  ) {
    super(config, formService, sessionStorageService);
  }

  get price(): number {
    return this.config.consultationRequestUpsellPrices[this.slug];
  }

  /**
   * Set the preferred medication on the session storage based on the vaginal cream option selected on continue button press.
   *
   * @param {string} optionSelected The option selected by the patient.
   */
  continueButtonPressed(optionSelected: string): void {
    this.sessionStorageService.preferredMedication =
      optionSelected === QuestionnaireAnswers.No
        ? this.sessionStorageService.orderedTests.find(
            (test) => test.customer_tests_type === CustomerTestsTypes.Individual
          ).customer_tests_name
        : optionSelected;
  }

  /**
   * Sets the options for the nausea and vomiting upsell.
   */
  protected override setOptions(): void {
    this.options = [
      new SelectableOption('I prefer taking oral medication.', QuestionnaireAnswers.No),
      new SelectableOption('I prefer Metronidazole Vaginal Gel 0.75% (Metrogel).', UpsellSlugs.MetronidazoleVaginalGel),
      new SelectableOption('I prefer Clindamycin Vaginal Cream 2% (Cleocin).', UpsellSlugs.ClindamycinVaginalCream),
    ];
  }
}
