import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ConsultationStatus } from '@enums/consultation-status';
import { QuestionnaireAnswers } from '@enums/questionnaire-answers';
import { UpsellSlugs } from '@enums/upsell-slugs';
import { DisqualifiedFieldEvent } from '@models/disqualified-field-event';
import { SelectableOption } from '@models/selectable-option';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { ConsultationRequestService } from '@services/consultation-request.service';
import { TreatmentConsultationQuestionnaireService } from '@services/treatment-consultation-questionnaire.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-uti-consultation-questions',
  templateUrl: './uti-consultation-questions.component.html',
  styleUrls: ['./uti-consultation-questions.component.scss'],
})
export class UtiConsultationQuestionsComponent implements OnInit, OnDestroy {
  readonly Validators: typeof Validators = Validators;

  private questionDisqualified: Set<string> = new Set();

  readonly defaultErrorMessage: string =
    'These conditions can be associated with a higher risk of complication from a UTI ' +
    'and require immediate in-person consultation, not treatment via TreatMyUTI.com.';

  readonly symptoms: string[] = [
    'Extreme Foul-Smelling Vaginal Discharge',
    'Vaginal Itching',
    'Blisters or Lesions',
    'Abnormal green, yellow, clear, or white discharge',
    'Rash',
  ];
  readonly questionnaireAnswers = QuestionnaireAnswers;

  readonly painFrequencyOptions: SelectableOption[] = [
    new SelectableOption('Constant'),
    new SelectableOption('Comes & Goes'),
  ];

  readonly pyridiumOptions: SelectableOption[] = [
    new SelectableOption(QuestionnaireAnswers.Yes, UpsellSlugs.Pyridium),
    new SelectableOption(QuestionnaireAnswers.No),
  ];

  readonly followUpUrineTestOptions: SelectableOption[] = [
    new SelectableOption(QuestionnaireAnswers.Yes, UpsellSlugs.FollowUpUrineTest),
    new SelectableOption(QuestionnaireAnswers.No),
  ];

  readonly utiTestId: string = '2749';

  @Input() medicalQuestionnaireForm: FormGroup;
  @Input() upsellForm: FormGroup;
  @Input() addressForm: FormGroup;
  @Input() patientInfoForm: FormGroup;

  @Output() labFinderModalShown: EventEmitter<void> = new EventEmitter<void>();

  private subscriptions: Subscription[] = [];
  private patientAge: number;

  showLabFinderModal: boolean = false;

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private consultationRequestService: ConsultationRequestService,
    private treatmentConsultationQuestionnaireService: TreatmentConsultationQuestionnaireService
  ) {}

  ngOnInit(): void {
    this.addFieldsConditionalValidators();
    this.prefillQuestions();
    this.patientAge = this.getPatientAge(
      new Date(this.consultationRequestService.consultationOrderDetail?.date_of_birth)
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * Listener for disqualified event, manage the disqualified answer to the list of disqualified answers.
   *
   * @param {DisqualifiedFieldEvent} disqualifiedAnswer the disqualified answer event
   */
  disqualifiedEventListener(disqualifiedAnswer: DisqualifiedFieldEvent): void {
    disqualifiedAnswer.isDisqualified
      ? this.questionDisqualified.add(disqualifiedAnswer.controlName)
      : this.questionDisqualified.delete(disqualifiedAnswer.controlName);
  }

  /**
   * Get the question label from the question control name
   *
   * @param {string} controlName the question control name
   */
  getQuestionLabel(controlName: string): string {
    return this.treatmentConsultationQuestionnaireService.getQuestionLabel(controlName);
  }

  /**
   * Get the question error message from the question control name
   *
   * @param {string} controlName the question control name
   */
  getErrorMessage(controlName: string): string {
    return this.treatmentConsultationQuestionnaireService.getErrorMessage(controlName) || this.defaultErrorMessage;
  }

  /**
   * Shows the lab finder modal.
   */
  presentLabFinderModal(): void {
    this.labFinderModalShown.emit();
    this.showLabFinderModal = true;
  }

  /**
   * Gets whether the patient is not urinating frequently.
   *
   * @returns {boolean} true if the patient is not urinating frequently; otherwise, false
   */
  get isNotUrinatingFrequently(): boolean {
    return this.medicalQuestionnaireForm?.get('urinatingFrequently').value === QuestionnaireAnswers.No;
  }

  /**
   * Gets whether the patient has vaginal irritation or abnormal discharge suggestive of a vaginal infection.
   *
   * @returns {boolean} true if the patient shows signs of a vaginal infection; otherwise, false
   */
  get hasVaginalIrritation(): boolean {
    return this.medicalQuestionnaireForm?.get('possibleVaginalInfection').value === QuestionnaireAnswers.Yes;
  }

  /**
   * Gets whether the patient is experiencing lower back pain.
   *
   * @returns {boolean} true if the patient is experiencing lower back pain; otherwise, false
   */
  get hasLowerBackPain(): boolean {
    return this.medicalQuestionnaireForm?.get('lowerBackPain').value === QuestionnaireAnswers.Yes;
  }

  /**
   * Gets whether the patient has had a kidney infection.
   *
   * @returns {boolean} true if the patient has had a kidney infection; otherwise, false
   */
  get hasHadKidneyInfection(): boolean {
    return this.medicalQuestionnaireForm?.get('previousKidneyInfection').value === QuestionnaireAnswers.Yes;
  }

  /**
   * Gets whether the patient has been diagnosed for UTI with similar symptoms.
   *
   * @returns {boolean} true if the patient has been diagnosed for UTI with similar symptoms; otherwise, false
   */
  get hasBeenDiagnosedForUtiWithSimilarSymptoms(): boolean {
    return this.medicalQuestionnaireForm?.get('diagnosedForUtiWithSimilarSymptoms').value === QuestionnaireAnswers.Yes;
  }

  /**
   * Gets whether the patient has diabetes.
   *
   * @returns {boolean} true if the patient has diabetes; otherwise, false
   */
  get hasDiabetes(): boolean {
    return this.medicalQuestionnaireForm?.get('diabetes').value === QuestionnaireAnswers.Yes;
  }

  /**
   * Gets whether the patient is currently not on insulin.
   *
   * @returns {boolean} true if the patient is currently not on insulin; otherwise, false
   */
  get isCurrentlyNotOnInsulin(): boolean {
    return this.medicalQuestionnaireForm?.get('insulin').value === QuestionnaireAnswers.No;
  }

  /**
   * Gets whether the patient has had a hemoglobin A1c test in the last year.
   *
   * @returns {boolean} true if the patient has had a hemoglobin A1c test in the last year; otherwise, false
   */
  get hadHemoglobinA1cTest(): boolean {
    return this.medicalQuestionnaireForm?.get('hemoglobinA1CTestedInLastYear').value === QuestionnaireAnswers.Yes;
  }

  /**
   * Gets whether the patient is between 66 and 80 years old.
   *
   * @returns {boolean} true if the patient is between 66 and 80 years old; otherwise, false
   */
  get isBetween66And80YearsOld(): boolean {
    return this.patientAge >= 66 && this.patientAge <= 80;
  }

  /**
   * Gets the pyridium upsell price.
   *
   * @returns {number} the price of the Pyridium upsell
   */
  get pyridiumPrice(): number {
    return this.config.consultationRequestUpsellPrices[UpsellSlugs.Pyridium];
  }

  /**
   * Gets the follow-up urine test upsell price.
   *
   * @returns {number} the price of the follow-up urine test upsell
   */
  get followUpUrineTestPrice(): number {
    return this.config.consultationRequestUpsellPrices[UpsellSlugs.FollowUpUrineTest];
  }

  /**
   * Adds the required and max length optional validator to the defined control
   * when answering "Yes" in the YesNoDetailsFieldComponent.
   */
  private addFieldsConditionalValidators(): void {
    this.subscriptions.push(
      this.addConditionalValidators('immuneSystemSuppressed', 'immuneSystemSuppressedDetails'),
      this.addConditionalValidators('diagnosedForUtiWithSimilarSymptoms', 'treatedForUtiInLast6Weeks'),
      this.addConditionalValidators('treatedForUtiInLast6Weeks', 'treatedForUtiInLast6WeeksDetails'),
      this.addConditionalValidators('traveledInternationallyInPast6Months', 'travelDetails'),
      this.addConditionalValidators('onMedication', 'medicationDetails', 1000),
      this.addConditionalValidators('hasAllergies', 'allergiesDetails', 1000),
      this.addConditionalValidators('hasOtherConditions', 'otherConditionsDetails', 397),
      this.dateOfBirthSubscription()
    );
  }

  /**
   * Adds conditional validators to the form controls, making the form control required if the yes/no form
   * control is true (yes).
   *
   * @param {string} yesNoControlName the name of the yes/no form control
   * @param {string} controlName      the name of the form control
   * @param {number} detailsMaxLength the maximum length of the details form control value
   *
   * @returns {Subscription} a subscription to the value changes of the yes/no form control
   */
  private addConditionalValidators(
    yesNoControlName: string,
    controlName: string,
    detailsMaxLength?: number
  ): Subscription {
    const control = this.medicalQuestionnaireForm?.get(controlName);

    return this.medicalQuestionnaireForm?.get(yesNoControlName).valueChanges.subscribe((value) => {
      if (value === QuestionnaireAnswers.Yes) {
        control.setValidators([
          Validators.required,
          ...(detailsMaxLength ? [Validators.maxLength(detailsMaxLength)] : []),
        ]);
      } else {
        control.clearValidators();
      }

      control.updateValueAndValidity();
    });
  }

  /**
   * Subscribes to changes in the date of birth form group and updates the age property.
   */
  private dateOfBirthSubscription(): Subscription {
    return this.patientInfoForm
      .get('birthday')
      .valueChanges.subscribe(
        (value: { year: number; month: number; day: number }) =>
          (this.patientAge = this.getPatientAge(new Date(value.year, value.month - 1, value.day)))
      );
  }

  /**
   * Prefills the questions form with the patient's previous answers.
   */
  private prefillQuestions(): void {
    const prescriptions = this.consultationRequestService.consultationOrderDetail?.consultationRequest?.prescriptions;
    const allergies = this.consultationRequestService.consultationOrderDetail?.consultationRequest?.allergies;
    const healthInfo = this.consultationRequestService.consultationOrderDetail?.consultationRequest?.health_info;
    const onMedication = this.validateAnswerIsNoOrEmpty(prescriptions)
      ? QuestionnaireAnswers.Yes
      : QuestionnaireAnswers.No;
    const hasAllergies = this.validateAnswerIsNoOrEmpty(allergies) ? QuestionnaireAnswers.Yes : QuestionnaireAnswers.No;
    const hasOtherConditions = this.validateAnswerIsNoOrEmpty(healthInfo)
      ? QuestionnaireAnswers.Yes
      : QuestionnaireAnswers.No;

    this.medicalQuestionnaireForm?.patchValue({
      onMedication,
      medicationDetails: prescriptions,
      hasAllergies,
      allergiesDetails: allergies,
      hasOtherConditions,
      otherConditionsDetails: healthInfo,
    });
  }

  /**
   * Validates the answer is not empty or "No".
   *
   * @param {string|null} answer the answer of the question
   */
  private validateAnswerIsNoOrEmpty(answer: string | null): boolean {
    return !(!answer || answer.trim().toUpperCase() === QuestionnaireAnswers.No.toUpperCase());
  }

  /**
   * Get disqualification or pending consultation status for the consultation because of disqualified questions
   */
  getConsultationStatus(): ConsultationStatus.Pending | ConsultationStatus.Disqualified {
    return this.questionDisqualified.size > 0 ? ConsultationStatus.Disqualified : ConsultationStatus.Pending;
  }

  /**
   * Get the patient age from the date of birth.
   *
   * @param {Date} dob the patient's date of birth
   */
  private getPatientAge(dob: Date): number {
    const currentDate = new Date();
    let age = currentDate.getFullYear() - dob.getFullYear();

    if (
      currentDate.getMonth() < dob.getMonth() ||
      (currentDate.getMonth() === dob.getMonth() && currentDate.getDate() < dob.getDate())
    ) {
      age--;
    }

    return age;
  }
}
