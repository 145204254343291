import { FormGroup } from '@angular/forms';
import { ConsultationStatus } from '@enums/consultation-status';
import { QuestionnaireAnswers } from '@enums/questionnaire-answers';
import { Treatment } from '@enums/treatment';

import { ConsultationRequestAdditionalInfo } from './consultation-request-additional-info';
import { ConsultationRequestHealthData } from './consultation-request-health-data';
import { ConsultationRequestHelper } from './consultation-request-helper';
import { ConsultationRequestOrderDetail } from './consultation-request-order-detail';
import { ConsultationRequestPayment } from './consultation-request-payment';

export class StdConsultationRequestRequest extends ConsultationRequestHealthData {
  consultation_status = ConsultationStatus.Pending;

  constructor(
    consultationRequestFormGroup: FormGroup,
    consultationRequestOrderDetail: ConsultationRequestOrderDetail,
    positiveTestsSlugs: string[],
    userId: number,
    orderSubtotal: number,
    deviceData: string
  ) {
    const formValues = consultationRequestFormGroup.value;

    super(
      formValues.medicalHistory.allergies,
      formValues.personal.birthday,
      consultationRequestOrderDetail.gender,
      formValues.medicalHistory.pregnant,
      formValues.medicalHistory.prescriptions,
      ConsultationRequestHelper.getSymptomsArrayFromValues(formValues.medicalHistory.symptoms)
    );

    this.additional_info = new ConsultationRequestAdditionalInfo(
      formValues.prescription.partner,
      formValues.prescription.treatment,
      formValues.personal
    );

    this.pharmacy_id = formValues.pharmacy.id || null;
    if (!formValues.pharmacy.id) {
      this.additional_info.pharmacy = formValues.additional_info.pharmacy;
    }

    this.date_of_birth = ConsultationRequestHelper.getDateDataString(this.dob);
    if (this.pregnant === QuestionnaireAnswers.Yes) {
      this.due_date = ConsultationRequestHelper.getDateDataString(formValues.medicalHistory.due_d);
    }

    this.email = formValues.contact.email;
    const ethnicity = formValues.medicalHistory.ethnicity;
    if (ethnicity) {
      this.ethnicity = ethnicity;
    }

    this.first_name = formValues.personal.firstName;
    const healthInfo = formValues.medicalHistory.health_info;
    if (healthInfo) {
      this.health_info = healthInfo;
    }

    if ([QuestionnaireAnswers.No, QuestionnaireAnswers.Unsure].includes(this.pregnant)) {
      this.last_menstrual_date = ConsultationRequestHelper.getDateDataString(formValues.medicalHistory.menstrual_d);
    }

    this.last_name = formValues.personal.lastName;
    this.num_treatments = this.additional_info.treatment === Treatment.SelfAndPartner ? 2 : 1;
    this.order_id = consultationRequestOrderDetail.transaction_id;
    this.payment = new ConsultationRequestPayment(formValues.payment, orderSubtotal, deviceData);
    this.phone = formValues.contact.phone;
    this.positive = positiveTestsSlugs;
    const race = formValues.medicalHistory.race;
    if (race) {
      this.race = race;
    }

    this.recharge = consultationRequestOrderDetail.recharge;
    this.sex = this.gender;
    this.sex_with = formValues.medicalHistory.sex_with;
    this.terms = formValues.terms;

    if (userId !== null) {
      this.user_id = userId;
    }

    this.type = formValues.type.consultationType;
    this.treatment_type = formValues.treatmentType;
  }
}
