import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params } from '@angular/router';
import { ConsultationStatus } from '@enums/consultation-status';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { ConsultationRequestService } from '@services/consultation-request.service';
import { LoadingService } from '@services/loading.service';
import { ScriptService } from '@services/script.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-schedule-consultation',
  templateUrl: './schedule-consultation.component.html',
  styleUrls: ['../consultation-request/consultation-request.component.scss', './schedule-consultation.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ScheduleConsultationComponent implements OnInit, OnDestroy {
  scheduleOnceId: string;
  consultationId: string;
  orderId: string;
  hash: string;
  errorMessage: string;
  patientPhone: string;
  routeSubscription: Subscription;

  constructor(
    @Inject(APP_CONFIG) public config: AppConfig,
    private scriptService: ScriptService,
    private acRoute: ActivatedRoute,
    private element: ElementRef,
    private consultationRequestService: ConsultationRequestService,
    private loadingService: LoadingService,
    private titleService: Title
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle(this.config.titles.consultationRequest);

    this.routeSubscription = this.acRoute.queryParams.subscribe({
      next: (params: Params) => {
        if (!params.scheduleOnceId || !params.order_id || !params.consultationId || !params.hash) {
          this.errorMessage = 'Invalid consultation data, please contact customer service.';
        }

        this.loadingService.toggleLoader(true);
        this.orderId = params.order_id;
        this.consultationId = params.consultationId;
        this.hash = params.hash;
        this.patientPhone = params.contact;
        this.consultationRequestValidation(params.scheduleOnceId);
      },
    });
  }

  /**
   * This method validate if the consultation request was created and initialize OnceHub
   *
   * @param scheduleOnceId Schedule once id
   */
  consultationRequestValidation(scheduleOnceId: string): void {
    //Validate if the order already has schedule a consultation
    this.consultationRequestService.getConsultationRequest(this.consultationId, this.orderId, this.hash).subscribe({
      next: (consultation) => {
        if (consultation.reschedule_url || consultation.consultation_status === ConsultationStatus.Canceled) {
          this.loadingService.toggleLoader(false);
          this.errorMessage = `Consultation was canceled or doesn't have a reschedule option, please contact customer service.`;
        }

        this.initOnceHub(scheduleOnceId);
        this.loadingService.toggleLoader(false);
      },
      error: () => {
        this.errorMessage = 'Invalid consultation data, please contact customer service.';
        this.loadingService.toggleLoader(false);
      },
    });
  }

  /**
   * This method loads the OnceHub script
   *
   * @param scheduleOnceId Schedule once id
   */
  initOnceHub(scheduleOnceId: string): void {
    this.scheduleOnceId = scheduleOnceId;
    this.scriptService.loadScript('https://cdn.oncehub.com/mergedjs/so.js');
    this.waitForOnceHubIFrameToBePresent();
    (window as any).SOAfterConfirmationFunction = (data) => {
      this.SOAfterConfirmationFunction(data);
    };
  }

  /**
   * Method triggered when a schedule was confirm on the OnceHub iframe
   *
   * @param data Once hub confirmation event data
   */
  SOAfterConfirmationFunction(data: {}): void {
    const body = {
      reschedule_url: data['bookingInfo'].CancelRescheduleLink,
    };
    this.consultationRequestService.update(this.consultationId, this.orderId, this.hash, body).subscribe({
      error: (error) => console.error('Unexpected error at the time of updating the consultation'),
    });
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
  }

  /**
   * This method waits for the OnceHub iframe to be present in the DOM, otherwise it will show an error message
   */
  private waitForOnceHubIFrameToBePresent(): void {
    let timesChecked: number = 0;
    const maxTimesToCheck: 20 = 20;

    const interval = setInterval(() => {
      const iframe = this.element.nativeElement.querySelector('iframe[name="ScheduleOnceIframe"]');
      timesChecked++;
      if (iframe) {
        clearInterval(interval);

        return;
      }
      if (!iframe && timesChecked >= maxTimesToCheck) {
        clearInterval(interval);
        this.errorMessage =
          'If the consultation scheduler has not loaded please reload the page or contact customer' + ' service.';
      }
    }, 500);
  }
}
