import { Inject, Injectable } from '@angular/core';
import { MedicalHistoryQuestionIds } from '@enums/medical-history-question-ids';
import { QuestionnaireAnswers } from '@enums/questionnaire-answers';
import { UpsellSlugs } from '@enums/upsell-slugs';
import { ConsultationQuestionAnswer } from '@models/consultation-request/consultation-question-answer';
import { ConsultationRequestRequest } from '@models/consultation-request/consultation-request-request';
import { OrderUpsell } from '@models/order-upsell';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { DynamicFormsService } from '@services/dynamic-forms.service';
import { FormService } from '@services/form.service';
import { SessionStorageService } from '@services/session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class TreatmentConsultationQuestionnaireService {
  /**
   * The upsell fields that are excluded from the "Chosen Upsells" question
   */
  protected readonly excludedUpsellsFromQuestion = [
    UpsellSlugs.MetronidazoleVaginalGel as string,
    UpsellSlugs.ClindamycinVaginalCream as string,
  ];

  protected readonly questions = {
    urinatingFrequently: 'Are you urinating frequently?',
    ableToUrinateOverLast4Hours: 'Have you been able to urinate over the last four hours?',
    urgesToGoToBathroom: 'Do you have the constant urge to go to the bathroom?',
    burningWhileUrinating:
      'During urination, do you experience any discomfort such as burning, stinging, tingling, or any other unusual sensations?',
    possibleVaginalInfection: 'Do you have vaginal irritation or abnormal discharge suggestive of a vaginal infection?',
    symptoms: 'What are your symptoms?',
    nauseaOrVomiting: 'Are you currently experiencing any new nausea or vomiting?',
    lowerBackPain: 'Do you have lower back, side (under the ribs), or groin pain?',
    overTheCounterMedication: 'Have you been taking any prescription or over-the-counter pain medication?',
    painFrequency: 'Is the pain...',
    previousKidneyInfection:
      'Have you ever had a kidney infection --not a bladder infection-- but a kidney infection before?',
    painSimilarToPreviousKidneyInfection:
      'Is the pain you are currently experiencing similar to your previous kidney infection?',
    hadUrologicalProcedure:
      'Have you had a urological procedure involving the bladder, kidneys, or ureters in the past 4-6 weeks?',
    nonMenstrualBloodInUrine: 'Do you have non-menstrual blood in your urine?',
    elevatedOralTemperature: 'Do you have an elevated oral temperature greater than 99.5° F?',
    moreThan4AntiBioticsInThePastYear:
      'Have you received treatment with UTI antibiotics more than four times in the past year?',
    diagnosedForUtiWithSimilarSymptoms:
      'Have you been diagnosed and treated in the past for a UTI (cystitis or bladder infection) with the similar symptoms that you are experiencing?',
    treatedForUtiInLast6WeeksDetails:
      'Have you been treated for a UTI (cystitis or bladder infections) in the last 4 weeks?',
    utiResolvedQuickly: 'Did your UTI resolve quickly with prescribed medication?',
    utisNotRespondingToTreatment: 'Are you getting UTIs frequently that are not responding to treatment?',
    diabetes: 'Do you have diabetes?',
    insulin: 'Are you currently on insulin?',
    hemoglobinA1CTestedInLastYear: 'Have you had a hemoglobin A1c test in the last year?',
    levelGreaterThan7: 'On your last Hemoglobin A1c test, was your level greater than 7?',
    presentlyHasKidneyStones: 'Do you currently have kidney stones?',
    catheterPlacedRecently: 'Have you recently had a catheter placed in your bladder for any reason?',
    possiblePregnancy: 'Are you currently pregnant or is it possible that you could currently be pregnant?',
    deliveredABabyInLastMonth: 'Have you delivered a baby within the last month?',
    breastfeeding: 'Are you currently breastfeeding?',
    exposedToSti:
      'Are you concerned that you may have been exposed to an STI such as gonorrhea, chlamydia or trichomoniasis?',
    immuneSystemSuppressedDetails:
      'Do you have any condition that might suppress your immune system, such as any form of cancer, HIV, AIDS, Lupus, or Rheumatoid Arthritis?',
    diagnosedWithQtProlongation: 'Have you or any family member ever been diagnosed with QT prolongation?',
    onChemotherapy: 'Are you currently on chemotherapy?',
    dehydratedInLast3Weeks: 'Have you been dehydrated in the last 2-3 weeks?',
    travelDetails: 'Have you traveled internationally within the past 6 months?',
    changesInMentalState:
      'Have you experienced any changes in your mental or cognitive state, such as confusion or disorientation?',
    changesInAppetite: 'Have you experienced any recent changes in your appetite or dietary preferences?',
    unintentionalWeightLossOrGain: 'Have you unintentionally lost or gained a significant amount of weight recently?',
    primaryCareVisitInLast2Years: 'Have you seen a primary care provider within the last 2 years?',
    abnormalKidneyFunctionTests:
      'Within the last 2 years, have you had blood tests to check your kidney function and were the results normal?',
    attestToSchedulingFollowUp:
      'Do you attest to scheduling a follow up visit with your primary care provider regarding your symptoms?',
    noMenstrualPeriodInLastYear:
      'Have you reached a stage where you have not had menstrual periods for 12 consecutive months or more, indicating postmenopausal status?',
  };

  /**
   * Questions that appear on every consultation request form/questionnaire.
   *
   * The responses for these questions are stored on the ConsultationRequest model itself, not within additional_info.
   */
  protected externalQuestions = {
    medicationDetails: 'Are you currently on any medication?',
    allergiesDetails: 'Do you have any allergies that you are aware of?',
    otherConditionsDetails: 'Do you have any other conditions?',
  };

  protected readonly errorMessages = {
    ableToUrinateOverLast4Hours:
      'The inability to urinate is not typically a symptom of an uncomplicated UTI. You will not be prescribed.',
    burningWhileUrinating:
      "Most UTIs are accompanied by burning, stinging, tingling or other uncomfortable sensations while urinating. Absence of these symptoms might indicate a different condition, in which case TreatMyUTI.com won't prescribe medication.",
    symptoms:
      'These symptoms imply other underlying conditions that are not currently treated by TreatMyUTI.com. You will not be prescribed medication.',
    hadUrologicalProcedure:
      'Recent urological procedures increase the risk of having a complicated UTIs case that is not treatable by TreatMyUTI.com.',
    elevatedOralTemperature:
      'A fever is not normally indicative of a urinary tract infection. You may not be treatable by TreatMyUTI.com.',
    moreThan4AntiBioticsInThePastYear:
      'Receiving treatment for UTIs more than four times in a given year may indicate a more complex issue. You will not be prescribed medication by TreatMyUTI.com.',
    treatedForUtiInLast6Weeks:
      'If you have been treated in the last 4 weeks and still have symptoms, you will not be prescribed by TreatMyUTI.com.',
    utiResolvedQuickly:
      'If your UTI did not resolve quickly with the same medication you are requesting, you will not be prescribed by TreatMyUTI.com.',
    utisNotRespondingToTreatment:
      'Frequent UTIs that do not respond to treatment could suggest a more serious or underlying health issue. You will not be prescribed medication by TreatMyUTI.com.',
    insulin:
      'Diabetics that are currently on insulin have an increased risk of having complicated UTIs that are not treatable by TreatMyUTI.com.',
    hemoglobinA1CTestedInLastYear:
      "Diabetic patients are required to provide their Hemoglobin A1c level to be treated for a UTI by TreatMyUTI.com. You may purchase a Hemoglobin A1c test via our sister site, <a href='https://www.healthlabs.com' target='_blank'>HealthLabs.com</a>.",
    levelGreaterThan7:
      'Diabetics with a Hemoglobin A1c level greater than 7 are associated with an increased risk of having complicated UTIs that are not treatable by TreatMyUTI.com.',
    changesInMentalState:
      'Changes in mental or cognitive states like confusion or disorientation can be serious and may require immediate medical attention. You will not be prescribed medication by TreatMyUTI.com.',
    presentlyHasKidneyStones:
      'Kidney stones may increase the risk of having a complicated UTI that is not treatable by TreatMyUTI.com.',
    catheterPlacedRecently:
      'Recent catheterization may increase your risk of having a complicated UTI that is not treatable by TreatMyUTI.com.',
    possiblePregnancy:
      "Pregnancy causes numerous changes in a woman's body and may increase the risk of having a complicated UTI case that is untreatable by TreatMyUTI.com.",
    deliveredABabyInLastMonth:
      'This condition can be associated with a higher risk of complication from a UTI and require an in-person consultation, not treatment via TreatMyUTI.com.',
    onChemotherapy:
      'Patients on chemotherapy may be subject to more complicated UTIs that are not treatable by TreatMyUTI.com.',
    changesInAppetite:
      'Recent changes in appetite or dietary preferences could be indicative of underlying health issues. You will not be prescribed medication by TreatMyUTI.com.',
    unintentionalWeightLossOrGain:
      'Unintentional weight changes can be a sign of serious health conditions. It is important to consult a healthcare provider for a comprehensive evaluation to determine the underlying cause. You will not be prescribed medication by TreatMyUTI.com.',
    primaryCareVisitInLast2Years:
      'Regular check-ups with a primary care provider are important for maintaining overall health. Not seeing a healthcare provider recently can lead to undiagnosed conditions. You will not be prescribed medication by TreatMyUTI.com.',
    abnormalKidneyFunctionTests:
      'Normal kidney function tests are required for safe prescribing. You will not be prescribed medication by TreatMyUTI.com.',
    attestToSchedulingFollowUp:
      'TreatMyUTI.com is unable to facilitate Telehealth services if you do not attest to the statement above. You may not be treatable by TreatMyUTI.com.',
  };

  constructor(
    public formService: FormService,
    private sessionStorageService: SessionStorageService,
    private dynamicFormsService: DynamicFormsService,
    @Inject(APP_CONFIG) protected config: AppConfig
  ) {}

  /**
   * Get the question error message by error key
   *
   * @param {string} errorMessageKey the question key to get the error message for
   */
  getErrorMessage(errorMessageKey): string {
    return this.errorMessages[errorMessageKey];
  }

  /**
   * Returns a promise with the ConsultationRequestRequest model that represents the backend payload.
   */
  async getConsultationRequestPayload(): Promise<ConsultationRequestRequest> {
    let consultationRequest = new ConsultationRequestRequest();
    // TODO: Remove backwards compatibility when only using dynamic forms.
    // Issue #2527: https://github.com/Medology/ng-checkout/issues/2527
    consultationRequest = this.isUsingDynamicQuestionnaire()
      ? this.setMedicalHistoryToConsultationPayload(
          consultationRequest,
          this.sessionStorageService.medicalHistoryQuestionnaireAnswers
        )
      : this.consultationRequestPayload(consultationRequest);
    consultationRequest.pharmacy_id = this.getPharmacy();
    consultationRequest.first_name = this.sessionStorageService.patient.name;
    consultationRequest.last_name = this.sessionStorageService.patient.last_name;
    consultationRequest.consultation_status = this.sessionStorageService.consultationStatus;
    consultationRequest.additional_info = await this.getAdditionalInfo();
    consultationRequest.date_of_birth = this.getDateOfBirth();

    return consultationRequest;
  }

  /**
   * Parse the form group questionnaire to a ConsultationQuestionAnswer array,
   * the sort is based on the order of the questions
   */
  private parseFormGroupToAdditionalInfoQuestionnaire(): ConsultationQuestionAnswer[] {
    const questionnaire: ConsultationQuestionAnswer[] = [];

    Object.entries(this.questions).forEach(([key, question]) => {
      const answer = this.getAnswer(key);
      if (answer) {
        questionnaire.push({ question, answer });
      }
    });

    return questionnaire;
  }

  /**
   * Builds the upsell object.
   */
  getUpsellsWithPrice(): OrderUpsell[] {
    let upsells = [];

    this.sessionStorageService.upsells.forEach((upsellName: string) =>
      upsells.push({
        name: upsellName,
        upsell_price: this.config.consultationRequestUpsellPrices[upsellName] * 100,
      })
    );

    return upsells;
  }

  /**
   * Get the question label
   *
   * @param {string} questionKey the question key to get the label for
   */
  getQuestionLabel(questionKey: string): string {
    return this.questions[questionKey] || this.externalQuestions[questionKey];
  }

  /**
   * Sets the medical history information on the provided consultation request payload.
   *
   * @param {ConsultationRequestRequest} payload the consultation request payload to update
   *
   * @returns {ConsultationRequestRequest} the payload with the medical history set
   * @param answers
   */
  setMedicalHistoryToConsultationPayload(
    payload: ConsultationRequestRequest,
    answers: object
  ): ConsultationRequestRequest {
    payload.allergies = answers[MedicalHistoryQuestionIds.AllergiesDetails] ?? QuestionnaireAnswers.No;
    payload.health_info = answers[MedicalHistoryQuestionIds.OtherConditionsDetails] ?? QuestionnaireAnswers.No;
    payload.prescriptions = answers[MedicalHistoryQuestionIds.MedicationDetails] ?? QuestionnaireAnswers.No;

    return payload;
  }

  /**
   * Parse the preferred medication into the questionnaire
   *
   * @param {ConsultationQuestionAnswer[]} questionnaire the questionnaire to push the preferred medication into
   */
  private parsePreferredMedicationIntoQuestionnaire(questionnaire: ConsultationQuestionAnswer[]): void {
    questionnaire.unshift({
      question: 'Preferred Medication',
      answer: this.getPreferredMedication(),
    });
  }

  /**
   * Get the preferred medication from the sessionStorage. Returns null if not set.
   *
   * @returns {string | null} the preferred medication or null if not set
   */
  private getPreferredMedication(): string | null {
    // TODO: Remove backwards compatibility when only using dynamic forms.
    // Issue #2527: https://github.com/Medology/ng-checkout/issues/2527
    if (this.isUsingDynamicQuestionnaire()) {
      return this.sessionStorageService.preferredMedication;
    }

    return this.sessionStorageService.questionnaire.preferredMedication;
  }

  /**
   * Parse the upsells into the questionnaire
   *
   * @param {ConsultationQuestionAnswer[]} questionnaire the questionnaire to push the upsells into
   */
  private parseUpsellsIntoQuestionnaire(questionnaire: ConsultationQuestionAnswer[]) {
    const upsells = this.sessionStorageService.upsells.filter(
      (upsell) => !this.excludedUpsellsFromQuestion.includes(upsell)
    );
    const orderedUpsells = [...upsells, ...this.sessionStorageService.orderedUpsells];

    // Remove duplicates with Set and join the array
    const upsellsAnswer = [...new Set(orderedUpsells)].join(', ');

    if (upsellsAnswer) {
      questionnaire.unshift({
        question: 'Chosen Upsells',
        answer: upsellsAnswer,
      });
    }
  }

  /**
   * Get the additional info for the consultation
   *
   * @returns {Promise<string>} promise that resolves to a JSON string with the additional consultation information
   */
  private async getAdditionalInfo(): Promise<string> {
    const patient = this.sessionStorageService.patient;
    const additional_info = {
      questionnaire: [],
      height: {
        feet: patient.height_feet,
        inches: patient.height_inches,
      },
      weight: patient.weight,
    };

    additional_info.questionnaire = await this.getParsedQuestionsAndAnswers();

    return JSON.stringify(additional_info);
  }

  /**
   * Retrieves and parses the questions and answers from the values stored in sessionStorage.
   *
   * @returns {Promise<ConsultationQuestionAnswer[]>} promise that resolves to an array of parsed question-answer pairs
   */
  private async getParsedQuestionsAndAnswers(): Promise<ConsultationQuestionAnswer[]> {
    // TODO: Remove backwards compatibility when only using dynamic forms.
    // Issue #2527: https://github.com/Medology/ng-checkout/issues/2527
    const questionsAndAnswers = this.isUsingDynamicQuestionnaire()
      ? await this.dynamicFormsService.parseQuestionsAndAnswersFromStorage(this.sessionStorageService.treatmentType, {
          treatmentName: this.sessionStorageService.getTreatmentPreferenceName(),
        })
      : this.parseFormGroupToAdditionalInfoQuestionnaire();

    this.parseUpsellsIntoQuestionnaire(questionsAndAnswers);
    this.parsePreferredMedicationIntoQuestionnaire(questionsAndAnswers);

    return questionsAndAnswers;
  }

  /**
   * Get the pharmacy id
   *
   * If the shipping address is set, it will return the delivery pharmacy id
   */
  private getPharmacy(): number {
    let pharmacyId = this.sessionStorageService.pharmacy.id;

    if (this.sessionStorageService.shippingAddress) {
      pharmacyId = this.config.deliveryPharmacyId;
    }

    return pharmacyId;
  }

  /**
   * Get the date of birth in the format of YYYY-MM-DD
   */
  private getDateOfBirth(): string {
    const { year, month, day } = this.sessionStorageService.patient.birthday;

    return `${year.toString()}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
  }

  /**
   * Checks if the dynamic questionnaire is being used based for the current treatment type.
   *
   * @returns {boolean} true if the dynamic questionnaire is being used, false otherwise
   */
  private isUsingDynamicQuestionnaire(): boolean {
    const treatmentType = this.sessionStorageService.treatmentType;

    return !!treatmentType && !!this.sessionStorageService.getQuestionnaireAnswers(treatmentType);
  }

  /**
   * Get the answer for the question, mapping to the details question if necessary
   *
   * @param {string} questionKey the question key to get the answer for
   */
  private getAnswer(questionKey: string): string {
    const answer = this.sessionStorageService.questionnaire?.[questionKey];

    return Array.isArray(answer) ? answer.join(', ') : answer;
  }

  /**
   * Returns the ConsultationRequestRequest common variables that represents the backend payload
   *
   * @param {ConsultationRequestRequest} consultationPayload The consultation request payload
   */
  private consultationRequestPayload(consultationPayload: ConsultationRequestRequest): ConsultationRequestRequest {
    consultationPayload.prescriptions = this.getAnswer('medicationDetails') ?? QuestionnaireAnswers.No;
    consultationPayload.allergies = this.getAnswer('allergiesDetails') ?? QuestionnaireAnswers.No;
    consultationPayload.health_info = this.getAnswer('otherConditionsDetails') ?? QuestionnaireAnswers.No;

    return consultationPayload;
  }
}
