import { Component, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { FormHelper } from '@common/form-helper';
import { ChooseAPharmacyComponent } from '@components/consultation-request/choose-a-pharmacy/choose-a-pharmacy.component';
import { UtiConsultationQuestionsComponent } from '@components/consultation-request/uti-consultation-questions/uti-consultation-questions.component';
import { ConsultationStatus } from '@enums/consultation-status';
import { Gender } from '@enums/gender';
import { QuestionnaireAnswers } from '@enums/questionnaire-answers';
import { MissingFormFieldsError } from '@errors/missing-form-fields-error';
import { UpdatePharmacyError } from '@errors/update-pharmacy-error';
import { ConsultationRequestOrderDetail } from '@models/consultation-request/consultation-request-order-detail';
import { DateSelection } from '@models/date-selection';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { ConsultationRequestService } from '@services/consultation-request.service';
import { FormService } from '@services/form.service';
import { SessionStorageService } from '@services/session-storage.service';
import { StorageService } from '@services/storage.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-uti-consultation-request-form',
  templateUrl: './uti-consultation-request-form.component.html',
  styleUrls: ['./uti-consultation-request-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UtiConsultationRequestFormComponent extends FormHelper implements OnInit, OnDestroy {
  @ViewChild('questionnaireComponent') questionnaireComponent: UtiConsultationQuestionsComponent;
  @ViewChild('chooseAPharmacyComponent') chooseAPharmacyComponent: ChooseAPharmacyComponent;
  readonly prescriptionConsultationPeriod: number = 28;
  isProcessing: boolean = false;
  errorMessage: string;
  submissionError: string;

  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private formService: FormService,
    private consultationRequestService: ConsultationRequestService,
    private router: Router,
    private storageService: StorageService,
    private sessionStorageService: SessionStorageService
  ) {
    super();
  }

  /**
   * Gets the minimum age allowed for the consultation.
   */
  get minAge(): number {
    return this.config.order.minAgeAllowed;
  }

  /**
   * Gets the maximum age allowed for the consultation.
   */
  get maxAge(): number {
    return this.config.maxAgeAllowed;
  }

  /**
   * Gets the UTI consultation form group.
   */
  get utiConsultationForm(): FormGroup {
    return this.formService.utiConsultationRequest;
  }

  /**
   * Gets the current consutation request order.
   *
   * @returns {ConsultationRequestOrderDetail} the consultation order details
   */
  get consultationOrderDetail(): ConsultationRequestOrderDetail {
    return this.consultationRequestService.consultationOrderDetail;
  }

  /**
   * Gets the patient info form group.
   *
   * @returns {FormGroup} the patient info form group
   */
  get patientInfoForm(): FormGroup {
    return this.utiConsultationForm.get('patient') as FormGroup;
  }

  /**
   * Get the FormGroup for the pharmacy section
   *
   * @returns {FormGroup} the pharmacy form group
   */
  get pharmacyForm(): FormGroup {
    return this.utiConsultationForm.get('pharmacy') as FormGroup;
  }

  /**
   * Get the FormGroup for the additional info section
   */
  get additionalInfoForm(): FormGroup {
    return this.utiConsultationForm.get('additional_info') as FormGroup;
  }

  /**
   * Gets the questionnaire form group inside the additional info form group.
   */
  get questionnaireForm(): FormGroup {
    return this.additionalInfoForm.get('questionnaire') as FormGroup;
  }

  /**
   * Gets the address form group.
   *
   * @returns {FormGroup} the address form group
   */
  get addressForm(): FormGroup {
    return this.utiConsultationForm.get('address') as FormGroup;
  }

  /**
   * Gets the upsell form group.
   *
   * @returns {FormGroup} the upsell form group
   */
  get upsellForm(): FormGroup {
    return this.utiConsultationForm.get('upsells') as FormGroup;
  }

  /**
   * Initializes the component.
   */
  ngOnInit() {
    this.consultationRequestService.storeOrderedUpsellsOnSessionStorage();
    this.addListenerToConsultationForm();
  }

  /**
   * Destroys the component.
   *
   * Executes any necessary logic after the component has been destroyed. The setConsultationStatus method is called here
   * to ensure the consultation status is correctly updated. This is important because another method that modifies the
   * consultation status is triggered when the child input components are destroyed. Therefore, we need to call this method
   * to guarantee it runs after the child components have been fully destroyed.
   */
  ngOnDestroy() {
    this.setConsultationStatus();
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * Add the necessary form's subscriptions to the subscriptions array.
   */
  addListenerToConsultationForm(): void {
    this.subscriptions.push(
      this.utiConsultationForm.valueChanges.subscribe(() => {
        if (this.utiConsultationForm.valid) {
          this.errorMessage = null;
        }
      })
    );
  }

  /**
   * Stores the consultation request data and navigates to the next page.
   */
  continue(): void {
    this.errorMessage = null;

    if (!this.shouldContinue()) {
      this.markAllFormControlsAsTouched();
      this.errorMessage = new MissingFormFieldsError().message;

      return;
    }

    this.isProcessing = true;

    if (this.shouldUpdatePharmacy()) {
      this.updatePharmacy();

      return;
    }

    this.handleSuccessWorkflow();
  }

  /**
   * Checks if the forms displayed on the page are valid.
   */
  private shouldContinue(): boolean {
    return (
      this.additionalInfoForm.valid &&
      this.patientInfoForm.valid &&
      this.addressForm.valid &&
      this.pharmacyForm.valid &&
      this.upsellForm.get('pyridium').valid &&
      this.upsellForm.get('followUpUrineTest').valid
    );
  }

  /**
   * Sets the consultation status to disqualified if the patient is not eligible for the consultation.
   */
  private setConsultationStatus() {
    if (
      this.isLessThan28DaysSinceLastTreatment() ||
      this.sessionStorageService.patient.gender === Gender.Male ||
      !new DateSelection(
        Number(this.sessionStorageService.patient.birthday.month),
        Number(this.sessionStorageService.patient.birthday.day),
        Number(this.sessionStorageService.patient.birthday.year)
      ).isAgeValid(this.minAge, this.maxAge)
    ) {
      this.sessionStorageService.consultationStatus = ConsultationStatus.Disqualified;
    }
  }

  /**
   * Stores the data that is needed for the consultation request completed page.
   */
  private storeConsultationRequestCompletedData(): void {
    this.sessionStorageService.patient = {
      name: this.patientInfoForm.get('first_name').value,
      last_name: this.patientInfoForm.get('last_name').value,
      email: this.consultationOrderDetail.email,
      phone: this.consultationOrderDetail.phone,
      birthday: this.patientInfoForm.get('birthday').value,
      gender: this.patientInfoForm.get('gender').value,
      height_feet: this.patientInfoForm.get('height_feet').value,
      height_inches: this.patientInfoForm.get('height_inches').value,
      weight: this.patientInfoForm.get('weight').value,
    };
    this.sessionStorageService.transactionId = this.consultationOrderDetail.transaction_id;
    this.sessionStorageService.consultationId = this.consultationOrderDetail.consultationRequest.id;
    this.sessionStorageService.hash = this.consultationOrderDetail.hash;
    this.sessionStorageService.questionnaire = this.questionnaireForm.value;
    this.sessionStorageService.address = this.addressForm.value;
    this.sessionStorageService.pharmacy = this.pharmacyForm.value;
    this.setUpsells();
  }

  /**
   * This is a backward compatible method to store the upsells on sessionStorage
   * as array to the new dynamic questionnaire form.
   */
  private setUpsells(): void {
    //this is the first questionnaire page, so we want to overwrite the upsells in case patient
    //move back, select another value for upsells and click continue
    const upsells = [];

    Object.values(this.upsellForm.value).forEach(
      (upsell: string) => upsell && upsell !== QuestionnaireAnswers.No && upsells.push(upsell)
    );

    this.sessionStorageService.upsells = upsells;
  }

  /**
   * Checks if the pharmacy should be updated.
   */
  private shouldUpdatePharmacy(): boolean {
    return this.consultationOrderDetail.consultationRequest.pharmacy.id !== this.pharmacyForm.value.id;
  }

  /**
   * Handles the success workflow for the consultation request continue button.
   */
  private handleSuccessWorkflow(): void {
    this.storeConsultationRequestCompletedData();
    this.isProcessing = false;
    this.sessionStorageService.consultationStatus = this.questionnaireComponent.getConsultationStatus();

    this.router.navigateByUrl('/consultation/yeast-infection-prevention');
  }

  /**
   * Marks all form controls as touched.
   */
  private markAllFormControlsAsTouched(): void {
    this.utiConsultationForm.get('patient').markAllAsTouched();
    this.utiConsultationForm.get('pharmacy').markAllAsTouched();
    this.additionalInfoForm.get('questionnaire').markAllAsTouched();
    this.utiConsultationForm.get('address').markAllAsTouched();
    this.utiConsultationForm.get('upsells').get('pyridium').markAllAsTouched();
    this.utiConsultationForm.get('upsells').get('followUpUrineTest').markAllAsTouched();
  }

  /**
   * Updates the pharmacy for the consultation request.
   */
  private updatePharmacy(): void {
    this.consultationRequestService
      .update(
        this.consultationOrderDetail.consultationRequest.id,
        this.consultationOrderDetail.transaction_id,
        this.consultationOrderDetail.hash,
        {
          pharmacy_id: this.pharmacyForm.value.id,
        }
      )
      .subscribe({
        next: () => this.handleSuccessWorkflow(),
        error: () => {
          this.isProcessing = false;
          this.submissionError = new UpdatePharmacyError().message;
        },
      });
  }

  /**
   * Get the difference in days between the given date and the current date.
   *
   * @param {Date} date - The date to compare.
   */
  private getDateDiffInDays(date: Date): number {
    const timeDiff = new Date().getTime() - date.getTime();

    return Math.abs(Math.floor(timeDiff / (1000 * 3600 * 24)));
  }

  /**
   * Check if it has been 28 days since the last treatment.
   */
  private isLessThan28DaysSinceLastTreatment(): boolean {
    const lastPrescribedAt = this.consultationOrderDetail.last_prescribed_at;

    if (!lastPrescribedAt) {
      return false;
    }
    return this.getDateDiffInDays(new Date(lastPrescribedAt)) < this.prescriptionConsultationPeriod;
  }
}
