import { FormGroup } from '@angular/forms';
import { ConsultationStatus } from '@enums/consultation-status';
import { ConsultationTreatmentTypes } from '@enums/consultation-treatment-types';
import { ConsultationTypes } from '@enums/consultation-types';
import { ConsultationRequestRequest } from '@models/consultation-request/consultation-request-request';

export class TreatmentConsultationRequest extends ConsultationRequestRequest {
  consultation_status = ConsultationStatus.Incomplete;
  type = ConsultationTypes.Asynchronous;

  constructor(
    patientInfoFormGroup: FormGroup,
    medicalHistoryFormGroup: FormGroup,
    pharmacyId: number,
    orderId: string,
    treatmentType: ConsultationTreatmentTypes
  ) {
    super();
    this.setPatientInfo(patientInfoFormGroup);
    this.setMedicalHistory(medicalHistoryFormGroup);
    this.pharmacy_id = pharmacyId;
    this.order_id = orderId;
    this.treatment_type = treatmentType || ConsultationTreatmentTypes.Uti;
  }

  /**
   * Sets the patient information based on the provided FormGroup.
   *
   * @param {FormGroup} patientInfoFormGroup the form group containing patient information
   */
  private setPatientInfo(patientInfoFormGroup: FormGroup): void {
    const formValues = patientInfoFormGroup.value;
    const { month, day, year } = formValues.birthday;
    this.date_of_birth = this.parseDateOfBirth(month, day, year);
    this.email = formValues.email;
    this.first_name = formValues.firstName;
    this.last_name = formValues.lastName;
    this.phone = formValues.phone;
  }

  /**
   * Sets the medical history based on the provided FormGroup.
   *
   * @param {FormGroup} medicalHistoryFormGroup the form group containing medical history information
   */
  private setMedicalHistory(medicalHistoryFormGroup: FormGroup): void {
    const formValues = medicalHistoryFormGroup.value;
    this.allergies = formValues.allergiesDetails;
    this.health_info = formValues.otherConditionsDetails;
    this.prescriptions = formValues.medicationDetails;
  }

  /**
   * Parses the components of a date of birth and returns a formatted date string.
   *
   * @param {string} month the month component of the date of birth
   * @param {string} day the day component of the date of birth
   * @param {string} year the year component of the date of birth
   *
   * @returns {string} a formatted date string in the 'YYYY-MM-DD' format
   */
  private parseDateOfBirth(month: string, day: string, year: string): string {
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  }
}
