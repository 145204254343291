import { ConsultationTreatmentTypes } from '@enums/consultation-treatment-types';
import { Gender } from '@enums/gender';

/**
 * Environment variables for use on the HealthLabs site, both in production and local environment.
 */
export const healthlabsCommonEnvironment = {
  domain: 'HealthLabs.com',
  ngFindalab: {
    searchEndpoint: '/labs/nearPostalCode',
    filterByStates: null,
    excludeStates: [],
    showPhone: false,
    filterByTestAndSite: true,
    searchTitle: 'Test Centers',
    searchDescription: undefined,
    pharmacySearchDescription:
      'To choose your pharmacy, enter your zip code into the search box below and click the search icon',
    locateMsg: 'Locate Me',
    labButtonText: 'Choose This Location',
    searchButtonText: '',
    showSearchIcon: true,
    labIconClass: 'fa fa-arrow-right ml-3 is-pulled-right',
    searchMessageNoResults:
      'Oops! Sorry, we could not find any testing centers near that location. Please try your search again with a different or less specific address.',
    inputGroup: {
      container: 'field has-addons',
      field: 'input control',
      button: 'control',
    },
    resultsZoom: 10,
    choosePharmacySearchTitle: '',
    showDayOfWeekFilter: true,
  },
  titles: {
    order: 'Order - HealthLabs.com',
    consultationRequest: 'Clinical Consultation & Prescription Request - HealthLabs.com',
    headlineCheckoutScheduled: 'Clinical Consultation & Prescription Request',
    headlineCheckoutAsync: 'Prescription Request',
    step1Title: 'Find a Test Center',
    selectedCenterLabel: 'Lab',
    orderPageHeader: {
      title: '',
      subtitle: '',
    },
    scheduledConsultationRequest: {
      title: 'Clinical Phone Consultation & Prescription Request',
      calendarDetails:
        'Please use the calendar below to pick a date and time for your consultation. Nothing will be scheduled until you have picked a date and time.',
      phoneNumberDetails: 'NOTE: Someone from our clinical team will call you on',
      availabilityDetails:
        'Based on availability, you may receive the call 60 minutes before or after your scheduled time.',
    },
  },
  buttons: {
    checkout: 'Place My Order',
    selectedCenterChange: 'Change Location',
  },
  order: {
    allowedGenders: [Gender.Female, Gender.Male],
    sexInvalidError: '',
    minAgeAllowed: 0,
  },
  styles: {
    chooseAPharmacy: {
      labButtonClass: 'is-primary',
      searchButtonClass: 'is-primary',
      googleMapsLabMarkerFillColor: '#5b90b3',
      googleMapsRecommendedMarkerFillColor: '#ffa500',
      googleMapsMarkerHoverFillColor: '#eb4d4c',
    },
    consultationRequest: {
      headlineClass: 'column is-offset-1',
    },
    healthLabsCoupon: {
      buttonColor: '',
    },
    orderCompleteInstructions: {
      cardContentClass: '',
    },
    orderBetterLab: {
      orderPlaceNotificationClass: 'is-success',
      currentTestCenterNotificationClass: 'is-primary',
      keepCurrentLabButtonClass: '',
      keepCurrentLabButtonColor: '',
    },
    testCenter: {
      labButtonClass: 'is-primary button animated-right',
      searchButtonClass: 'is-primary',
      googleMapsLabMarkerFillColor: '#5b90b3',
      googleMapsRecommendedMarkerFillColor: '#ffa500',
      googleMapsMarkerHoverFillColor: '#eb4d4c',
    },
  },
  shopperApproved: {
    site: 25124,
    token: '201H13Sj',
  },
  orderCompleteSummary: {
    testOrderedWording: {
      single: 'Test Ordered',
      plural: 'Tests Ordered',
    },
  },
  enablePayLater: false,
  enableBitPay: true,
  enableOrderUpgrade: false,
  enableOrderMedicalHistory: false,
  enableTosOnOrderPage: false,
  forceEmailResults: true,
  faviconFile: 'healthlabs-favicon.ico',
  inHomeCollectionAllowed: true,
  displayFindTestsModal: true,
  displaySisterSiteBanner: false,
  phone: '1 (800) 579-3914',
  email: 'support@healthlabs.com',
  validateSelectedLabNetwork: true,
  getNetworksFromTests: true,
  verifyTestsCanBeOrdered: true,
  isPartnerTestingAllowed: true,
  orderSummary: {
    cartAdditionalItems: [],
    allowEditingCart: false,
    editCartButtonText: '',
    displayCartSummaryTableHeaders: true,
    testsPrefix: '',
  },
  allowedRoutes: ['/*'],
  showCouponCodeInSummary: true,
  consultationRequestUpsellPrices: {},
  findalabMapId: '838e4d0c76cf80ed',
  maxAgeAllowed: 150,
  sentryDsn: 'https://9a6f2e2dd0324a6e898b1a275d0afb88@o510915.ingest.us.sentry.io/5607267',
  treatmentFormSettings: {
    [ConsultationTreatmentTypes.Uti]: null,
    [ConsultationTreatmentTypes.YeastInfection]: null,
    [ConsultationTreatmentTypes.BacterialVaginosis]: null,
    [ConsultationTreatmentTypes.StdPrevention]: '4',
    [ConsultationTreatmentTypes.Std]: null,
  },
};
