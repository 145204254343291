import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ConsultationTreatmentTypes } from '@enums/consultation-treatment-types';
import { QuestionnaireAnswers } from '@enums/questionnaire-answers';
import { UpsellSlugs } from '@enums/upsell-slugs';
import { SelectableOption } from '@models/selectable-option';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { FormService } from '@services/form.service';
import { SessionStorageService } from '@services/session-storage.service';

@Component({ template: '' })
export class UtiConsultationRequestUpsellComponent implements OnInit {
  options: SelectableOption[];
  upsellsForm: FormGroup;
  ConsultationTreatmentTypes: typeof ConsultationTreatmentTypes = ConsultationTreatmentTypes;
  protected slug: UpsellSlugs;

  constructor(
    @Inject(APP_CONFIG) protected config: AppConfig,
    protected formService: FormService,
    protected sessionStorageService: SessionStorageService
  ) {}

  /**
   * Initializes the component.
   */
  ngOnInit(): void {
    this.upsellsForm = this.formService.utiConsultationRequest.get('upsells') as FormGroup;
    this.setOptions();
  }

  /**
   * Retrieves the price of a consultation request upsell based on the slug.
   *
   * @returns {number} the price of the consultation request upsell
   */
  get price(): number {
    return this.config.consultationRequestUpsellPrices[this.slug];
  }

  /**
   * Retrieves the treatment type from the session storage service.
   *
   * @returns {ConsultationTreatmentTypes} the current consultation request treatment type
   */
  get treatmentType(): ConsultationTreatmentTypes {
    return this.sessionStorageService.treatmentType;
  }

  /**
   * Sets the options for the component, used to populate a list of selectable options.
   */
  protected setOptions(): void {
    this.options = [
      new SelectableOption(QuestionnaireAnswers.Yes, this.slug),
      new SelectableOption(QuestionnaireAnswers.No),
    ];
  }
}
