import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ConsultationTreatmentTypes } from '@enums/consultation-treatment-types';
import { ConsultationRequestError } from '@errors/consultation-request-error';
import { ConsultationRequestExpiredSessionError } from '@errors/consultation-request-expired-session-error';
import { ConsultationRequestInvalidLinkError } from '@errors/consultation-request-invalid-link-error';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import * as Sentry from '@sentry/angular';
import { ConsultationRequestService } from '@services/consultation-request.service';
import { LoadingService } from '@services/loading.service';
import { SessionStorageService } from '@services/session-storage.service';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ConsultationRequestResolver {
  constructor(
    @Inject(APP_CONFIG) protected config: AppConfig,
    private consultationRequestService: ConsultationRequestService,
    private loadingService: LoadingService,
    private sessionStorageService: SessionStorageService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    this.loadingService.toggleLoader(true);
    const treatmentType = route.params.treatmentType?.replace(/-/g, '_') || ConsultationTreatmentTypes.Uti;

    if (!route.queryParams.order_id || !route.queryParams.hash) {
      const error = new ConsultationRequestExpiredSessionError();

      return of({ error, errorMessage: error.message });
    }

    return this.consultationRequestService
      .getConsultationOrderDetails(
        route.queryParams.order_id,
        decodeURIComponent(route.queryParams.hash),
        treatmentType
      )
      .pipe(
        tap(
          () =>
            (this.sessionStorageService.orderedTests = this.consultationRequestService.consultationOrderDetail.tests)
        ),
        catchError((error: any) => {
          let errorMessage =
            error instanceof ConsultationRequestError
              ? error.message
              : new ConsultationRequestInvalidLinkError(this.config.email).message;

          if (
            error?.error?.code === 403 &&
            error?.error?.message &&
            !error.error.message.includes('We are very sorry, it seems like there was a problem.')
          ) {
            errorMessage = error.error.message;
          }

          //@todo remove this after fixing the hash problem. Issue: https://github.com/Medology/ng-checkout/issues/2263
          Sentry.captureException(`Consultation Request Error: ${error.message}`, {
            level: 'error',
            extra: {
              transactionId: route.queryParams.order_id ?? 'transactionId not found',
              hash: route.queryParams.hash ?? 'hash not found',
              decodedHash: decodeURIComponent(route.queryParams.hash) ?? 'hash not found',
            },
          });

          return of({ error, errorMessage });
        })
      );
  }
}
