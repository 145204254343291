import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormHelper } from '@common/form-helper';
import { Month } from '@common/month';
import { ConsultationRequestService } from '@services/consultation-request.service';
import { FormService } from '@services/form.service';

import { dateValidator } from '../../../validators/date-validator';

@Component({
  selector: 'app-consultation-request-personal-information',
  templateUrl: './personal-information.component.html',
  styleUrls: ['./personal-information.component.scss'],
})
export class PersonalInformationComponent extends FormHelper implements OnInit {
  @Input() calendarOptions: { months: Month[]; days: number[]; years: number[] };

  personalInfoForm: FormGroup;

  /**
   * Gets the date of birth from the form.
   */
  get dob(): Date | null {
    return this.getDob(this.personalInfoForm);
  }

  constructor(private formService: FormService, private consultationRequestService: ConsultationRequestService) {
    super();
  }

  ngOnInit(): void {
    this.personalInfoForm = this.formService.consultationRequest.get('personal') as FormGroup;
    this.setDynamicPatientInfoFormValidators();
    if (this.consultationRequestService.consultationOrderDetail) {
      this.prefillPersonalInformation();
    }
  }

  /**
   * Determines if a form control is invalid.
   *
   * @param {string} controlName The name of the form control to check for validity.
   */
  invalidControl(controlName: string): boolean {
    return this.isInvalid(this.personalInfoForm.get(controlName));
  }

  /**
   * Retrieves the error message for an invalid control.
   *
   * @param {string} controlName the name of the control in the form group
   * @param {string} displayName the display name of the control
   *
   * @returns {string} the error message for the invalid control
   */
  getInvalidControlErrorMessage(controlName: string, displayName: string): string {
    return this.getControlValidationErrorMessage(this.personalInfoForm.get(controlName), displayName);
  }

  /**
   * Determines if the birthdate is filled out.
   */
  birthdateFilled(): boolean {
    return ['month', 'day', 'year'].every((controlName: string) => {
      return !this.invalidControl(`birthday.${controlName}`);
    });
  }

  /**
   * Sets up dynamic validators.
   */
  private setDynamicPatientInfoFormValidators(): void {
    const personalInfoForm = this.personalInfoForm;
    const birthDayControl = personalInfoForm.get('birthday');
    birthDayControl.setValidators(dateValidator());
  }

  /**
   * Fill form personal information form fields
   */
  private prefillPersonalInformation() {
    const dob = new Date(this.consultationRequestService.consultationOrderDetail.date_of_birth);
    this.personalInfoForm.patchValue({
      firstName: this.consultationRequestService.consultationOrderDetail.first_name,
      lastName: this.consultationRequestService.consultationOrderDetail.last_name,
      birthday: {
        month: dob.getMonth() + 1,
        day: dob.getDate(),
        year: dob.getFullYear(),
      },
    });
  }
}
