import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MedicalHistoryQuestionsComponent } from '@components/consultation-request/medical-history-questions/medical-history-questions.component';
import { DynamicQuestionnaireFormComponent } from '@components/dynamic-forms/dynamic-questionnaire-form/dynamic-questionnaire-form.component';
import { ConsultationStatus } from '@enums/consultation-status';
import { ConsultationTreatmentTypes } from '@enums/consultation-treatment-types';
import { Gender } from '@enums/gender';
import { ConsultationRequestFormAlreadySubmittedError } from '@errors/consultation-request-form-already-submitted-error';
import { MissingFormFieldsError } from '@errors/missing-form-fields-error';
import { ConsultationRequestOrderDetail } from '@models/consultation-request/consultation-request-order-detail';
import { ExternalData } from '@models/dynamic-forms/external-data';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { ConsultationRequestService } from '@services/consultation-request.service';
import { DomainService } from '@services/domain.service';
import { DynamicFormsService } from '@services/dynamic-forms.service';
import { ErrorHandlerService } from '@services/error-handler.service';
import { FormService } from '@services/form.service';
import { LoadingService } from '@services/loading.service';
import { SessionStorageService } from '@services/session-storage.service';
import { TreatmentConsultationQuestionnaireService } from '@services/treatment-consultation-questionnaire.service';
import { from, Observable, switchMap } from 'rxjs';

@Component({
  selector: 'app-consultation-std-prevention',
  templateUrl: './consultation-std-prevention.component.html',
  styleUrls: [
    './../consultation-request/consultation-request.component.scss',
    'consultation-std-prevention.component.scss',
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ConsultationStdPreventionComponent implements OnInit {
  @ViewChild('medicalQuestionsForm', { static: false }) private medicalQuestionsForm: DynamicQuestionnaireFormComponent;
  @ViewChild('medicalHistoryQuestionsForm', { static: false })
  private medicalHistoryQuestionsForm: MedicalHistoryQuestionsComponent;

  consultationRequestTreatmentType: ConsultationTreatmentTypes = ConsultationTreatmentTypes.StdPrevention;
  questionIds: string[] = [];
  errorMessage: string;
  isProcessingRequest: boolean = false;
  submitError: string;

  readonly preferredMedication: string = 'Doxycycline';

  /**
   * Get the FormGroup for the std prevention consultation form
   */
  get consultationForm(): FormGroup {
    return this.formService.stdPreventionConsultationRequest;
  }

  /**
   * Get the order detail
   */
  get orderDetail(): ConsultationRequestOrderDetail {
    return this.consultationRequestService.consultationOrderDetail;
  }

  /**
   * Gets the FormGroup for the pharmacy section.
   */
  get pharmacyForm(): FormGroup {
    return this.consultationForm.get('pharmacy') as FormGroup;
  }

  /**
   * Gets the date of birth.
   */
  get dob(): Date | null {
    return this.orderDetail.date_of_birth ? new Date(this.orderDetail.date_of_birth) : null;
  }

  /**
   * Gets the patient information form group.
   */
  get patientInfoForm(): FormGroup {
    return this.consultationForm.get('patient') as FormGroup;
  }

  /**
   * Get the FormGroup for the terms section
   */
  get termsForm(): FormGroup {
    return this.consultationForm.get('terms') as FormGroup;
  }

  /**
   * Return true if the current patient is female
   */
  get isFemale(): boolean {
    return this.patientInfoForm.value.gender === Gender.Female;
  }

  /**
   * Gets the external validation data for the dynamic questionnaire form.
   */
  get externalData(): ExternalData {
    return {
      gender: this.patientInfoForm.value.gender,
    };
  }

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    public consultationRequestService: ConsultationRequestService,
    public domainService: DomainService,
    private activatedRoute: ActivatedRoute,
    public loadingService: LoadingService,
    private formService: FormService,
    private storageService: SessionStorageService,
    private router: Router,
    private treatmentConsultationQuestionnaireService: TreatmentConsultationQuestionnaireService,
    private dynamicFormsService: DynamicFormsService,
    private errorHandleService: ErrorHandlerService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(({ consultationOrderDetails }) => {
      if (consultationOrderDetails?.error) {
        this.errorMessage = consultationOrderDetails.errorMessage;
        this.loadingService.toggleLoader(false);

        return;
      }

      this.loadConsultations();
    });
  }

  /**
   * Retrieves consultations
   */
  loadConsultations(): void {
    const filters = {
      consultations_status: ConsultationStatus.Incomplete,
      treatment_type: this.consultationRequestTreatmentType,
    };

    this.consultationRequestService
      .getConsultations(this.orderDetail?.transaction_id, this.orderDetail?.hash, JSON.stringify(filters))
      .subscribe({
        next: (data) => {
          if (!data.data.length) {
            this.errorMessage = new ConsultationRequestFormAlreadySubmittedError(this.config.email).message;
            this.loadingService.toggleLoader(false);

            return;
          }

          this.consultationForm
            .get('patient')
            .get('gender')
            .valueChanges.subscribe((gender) => this.toggleIsFemaleValidators(gender));
          this.consultationRequestService.consultationOrderDetail.consultationRequest = data.data[0];
          this.setQuestions().then(() => this.loadingService.toggleLoader(false));
        },
        error: () => {
          this.errorMessage = `We are very sorry, it seems like there was a problem. Please contact support at ${this.config.email}.`;
          this.loadingService.toggleLoader(false);
        },
      });
  }

  /**
   * Toggle the validators for the "isFemale" controls
   *
   * @param {"F"|"M"} gender
   */
  toggleIsFemaleValidators(gender: 'F' | 'M'): void {
    this.termsForm.get('tos3').removeValidators(Validators.requiredTrue);
    if (gender === 'F') {
      this.termsForm.get('tos3').setValidators(Validators.requiredTrue);
    }

    this.termsForm.get('tos3').updateValueAndValidity();
  }

  /**
   * Submit the std prevention consultation form
   */
  submit(): void {
    if (!this.shouldContinue()) {
      return;
    }

    this.isProcessingRequest = true;
    this.storeConsultationRequestCompletedData();
    this.updateConsultation().subscribe({
      next: () => this.onRequestSuccess(),
      error: () => this.onRequestError(),
    });
  }

  /**
   * Stores the data that is needed for the consultation request completed page.
   */
  private storeConsultationRequestCompletedData(): void {
    this.storageService.treatmentType = this.consultationRequestTreatmentType;
    this.storageService.consultationStatus = this.getConsultationStatus();
    this.storageService.patient = {
      name: this.patientInfoForm.get('first_name').value,
      last_name: this.patientInfoForm.get('last_name').value,
      email: this.orderDetail.email,
      phone: this.orderDetail.phone,
      birthday: this.patientInfoForm.get('birthday').value,
      gender: this.patientInfoForm.get('gender').value,
      height_feet: this.patientInfoForm.get('height_feet').value,
      height_inches: this.patientInfoForm.get('height_inches').value,
      weight: this.patientInfoForm.get('weight').value,
    };
    this.storageService.transactionId = this.orderDetail.transaction_id;
    this.storageService.consultationId = this.consultationRequestService.consultationOrderDetail.consultationRequest.id;
    this.storageService.hash = this.orderDetail.hash;
    this.storageService.pharmacy = this.pharmacyForm.value;
    this.storageService.medicalHistoryQuestionnaireAnswers = this.medicalHistoryQuestionsForm.form.value;
    this.storageService.preferredMedication = this.preferredMedication;
  }

  /**
   * Validate the consultation form
   */
  private shouldContinue(): boolean {
    this.submitError = null;
    if (!this.isFormValid()) {
      this.submitError = new MissingFormFieldsError().message;
      if (!this.pharmacyForm.value.id) {
        this.submitError = `Please select a pharmacy.`;
      }

      this.markAllFormControlsAsTouched();

      return false;
    }

    return true;
  }

  /**
   * Redirect to the consultation complete page
   */
  private onRequestSuccess(): void {
    this.router.navigateByUrl('/std-prevention/consultation-request-completed');
  }

  /**
   * Set the request error message
   */
  private onRequestError(): void {
    this.isProcessingRequest = false;
    this.submitError = `There was an error processing your request. Please contact customer support.`;
  }

  /**
   * Sets the questions and upsells ids for the current treatment type.
   *
   * @returns {Promise<void | string>} the question ids or an error message
   */
  private setQuestions(): Promise<void | string> {
    return this.dynamicFormsService
      .getQuestionnaire(this.consultationRequestTreatmentType)
      .then((questionnaire) => {
        this.questionIds = questionnaire.questionIds;
      })
      .catch(() => (this.errorMessage = this.errorHandleService.defaultErrorMessage));
  }

  /**
   * Determines if the patient has disqualifying answers in the questionnaire.
   *
   * @returns {boolean} true if the patient has disqualifying answers, otherwise false
   */
  private getConsultationStatus(): ConsultationStatus {
    const isDisqualified = this.medicalQuestionsForm.questions.some(
      (question) =>
        this.dynamicFormsService.getQuestionVisibleWarnings(question, this.medicalQuestionsForm.form).length > 0
    );

    return isDisqualified ? ConsultationStatus.Disqualified : ConsultationStatus.Pending;
  }

  /**
   * Marks all form controls as touched.
   */
  private markAllFormControlsAsTouched(): void {
    this.medicalQuestionsForm.form.markAllAsTouched();
    this.medicalHistoryQuestionsForm.form.markAllAsTouched();
    this.consultationForm.markAllAsTouched();
  }

  /**
   * Checks if the forms displayed on the page are valid.
   *
   * @returns {boolean} true if all forms are valid, otherwise false
   */
  private isFormValid(): boolean {
    return (
      this.consultationForm.valid && this.medicalQuestionsForm.form.valid && this.medicalHistoryQuestionsForm.form.valid
    );
  }

  /**
   * Update the consultation request
   */
  private updateConsultation(): Observable<any> {
    return from(this.treatmentConsultationQuestionnaireService.getConsultationRequestPayload()).pipe(
      switchMap((consultationRequestPayload) => {
        return this.consultationRequestService.update(
          this.consultationRequestService.consultationOrderDetail.consultationRequest.id.toString(),
          this.orderDetail.transaction_id,
          this.orderDetail.hash,
          consultationRequestPayload
        );
      })
    );
  }
}
