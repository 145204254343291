import { ConsultationTreatmentTypes } from '@enums/consultation-treatment-types';
import { ConsultationRequestPharmacy } from '@models/consultation-request/consultation-request-pharmacy';

export class ConsultationRequest {
  id: string;
  first_name: string;
  last_name: string;
  allergies: string | null;
  prescriptions: string | null;
  health_info: string | null;
  phone: string;
  reschedule_url: string;
  consultation_status: string;
  treatment_type: ConsultationTreatmentTypes;
  pharmacy: ConsultationRequestPharmacy;
}
