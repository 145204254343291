import { ConsultationTreatmentTypes } from '@enums/consultation-treatment-types';
import { Gender } from '@enums/gender';

/**
 * Environment variables for use on the STDcheck site, both in production and local environment.
 */
export const stdcheckCommonEnvironment = {
  domain: 'STDcheck.com',
  ngFindalab: {
    searchEndpoint: '/labs/nearPostalCode',
    filterByStates: null,
    excludeStates: [],
    showPhone: false,
    filterByTestAndSite: false,
    searchTitle: 'STD Test Centers',
    searchDescription: undefined,
    pharmacySearchDescription:
      'To choose your pharmacy, enter your zip code into the search box below and click the search icon',
    locateMsg: 'Locate Me',
    labButtonText: 'Choose This Location',
    searchButtonText: '',
    showSearchIcon: true,
    labIconClass: 'fa fa-arrow-right ml-3 is-pulled-right',
    searchMessageNoResults:
      'Oops! Sorry, we could not find any testing centers near that location. Please try your search again with a different or less specific address.',
    inputGroup: {
      container: 'field has-addons',
      field: 'input control',
      button: 'control',
    },
    resultsZoom: 10,
    choosePharmacySearchTitle: '',
    showDayOfWeekFilter: true,
  },
  titles: {
    order: 'STD Testing - Secure Online STD Test Ordering',
    consultationRequest: 'Clinical Consultation Request - STDcheck.com',
    headlineCheckoutScheduled: 'Clinical Consultation & Prescription Request',
    headlineCheckoutAsync: 'Prescription Request',
    step1Title: 'Find a Test Center',
    selectedCenterLabel: 'Lab',
    orderPageHeader: {
      title: '',
      subtitle: '',
    },
    scheduledConsultationRequest: {
      title: 'Clinical Phone Consultation & Prescription Request',
      calendarDetails:
        'Please use the calendar below to pick a date and time for your consultation. Nothing will be scheduled until you have picked a date and time.',
      phoneNumberDetails: 'NOTE: Someone from our clinical team will call you on',
      availabilityDetails:
        'Based on availability, you may receive the call 60 minutes before or after your scheduled time.',
    },
  },
  buttons: {
    checkout: 'Place Your Order',
    selectedCenterChange: 'Change Location',
  },
  order: {
    allowedGenders: [Gender.Female, Gender.Male],
    sexInvalidError: '',
    minAgeAllowed: 16,
  },
  styles: {
    chooseAPharmacy: {
      labButtonClass: 'is-secondary-orange has-text-white',
      searchButtonClass: 'is-green has-text-white',
      googleMapsLabMarkerFillColor: '#ed9013',
      googleMapsRecommendedMarkerFillColor: '#ffa500',
      googleMapsMarkerHoverFillColor: '#eb4d4c',
    },
    consultationRequest: {
      headlineClass: 'column is-offset-1',
    },
    healthLabsCoupon: {
      buttonColor: 'secondary-orange',
    },
    orderCompleteInstructions: {
      cardContentClass: 'has-background-green-light',
    },
    orderBetterLab: {
      orderPlaceNotificationClass: 'is-warning',
      currentTestCenterNotificationClass: 'is-info',
      keepCurrentLabButtonClass: 'is-uppercase',
      keepCurrentLabButtonColor: 'secondary-orange',
    },
    testCenter: {
      labButtonClass: 'is-secondary-orange has-text-white button animated-right',
      searchButtonClass: 'is-secondary-orange has-text-white',
      googleMapsLabMarkerFillColor: '#ed9013',
      googleMapsRecommendedMarkerFillColor: '#ffa500',
      googleMapsMarkerHoverFillColor: '#eb4d4c',
    },
  },
  shopperApproved: {
    site: 10549,
    token: '4d0GYgHR',
  },
  orderCompleteSummary: {
    testOrderedWording: {
      single: 'Test Ordered',
      plural: 'Tests Ordered',
    },
  },
  enablePayLater: true,
  enableBitPay: true,
  enableOrderUpgrade: true,
  enableOrderMedicalHistory: false,
  enableTosOnOrderPage: false,
  forceEmailResults: false,
  faviconFile: 'stdcheck-favicon.ico',
  inHomeCollectionAllowed: true,
  displayFindTestsModal: false,
  displaySisterSiteBanner: true,
  phone: '1-800-456-2323',
  email: 'support@stdcheck.com',
  validateSelectedLabNetwork: false,
  getNetworksFromTests: false,
  verifyTestsCanBeOrdered: false,
  isPartnerTestingAllowed: true,
  orderSummary: {
    cartAdditionalItems: [],
    allowEditingCart: true,
    editCartButtonText: 'Edit/Add',
    displayCartSummaryTableHeaders: true,
    testsPrefix: '',
  },
  allowedRoutes: ['/*'],
  showCouponCodeInSummary: true,
  consultationRequestUpsellPrices: {},
  findalabMapId: 'ea1612ccbe63ba5f',
  maxAgeAllowed: 150,
  sentryDsn: 'https://eb95b1465b5346148828776acb40c62c@o510915.ingest.us.sentry.io/6105269',
  treatmentFormSettings: {
    [ConsultationTreatmentTypes.Uti]: null,
    [ConsultationTreatmentTypes.YeastInfection]: null,
    [ConsultationTreatmentTypes.BacterialVaginosis]: null,
    [ConsultationTreatmentTypes.StdPrevention]: '4',
    [ConsultationTreatmentTypes.Std]: null,
  },
};
